.l-sec {
	&--line {
		position: relative;

		&--top {
			padding-top: 95px;

			@include gridle_state(laptop) {
				padding-top: 60px;
			}

			@include gridle_state(laptop-middle) {
				padding-top: 50px;
			}

			@include gridle_state(laptop-small) {
				padding-top: 40px;
			}

			@include gridle_state(tablet) {
				padding-top: 30px;
			}

			&:before {
				display: block;

				position: absolute;

				top: 0;
				left: 50%;

				height: 1px;
				width: calc(100% - 40px);

				max-width: 1210px;

				background: $color_red;

				content: '';

				transform: translateX(-50%);
			}
		}
	}
}
