@import "node_modules/gridle/sass/gridle/gridle";
@import 'node_modules/gridle/sass/gridle/gridle-flex';

@include gridle_setup((
		context: 12,
		gutter-width: 0
));

@include gridle_register_state (inner, (
		max-width: $size_inner,
		classes: false
));

@include gridle_register_state (desktop, (
		max-width: 1920px,
		classes: false
));

@include gridle_register_state (laptop, (
		max-width: 1600px,
		classes: false
));

@include gridle_register_state (laptop-middle, (
		max-width: 1440px
));

@include gridle_register_state (laptop-middle-xs, (
		max-width: 1366px,
		classes: false
));

@include gridle_register_state (laptop-small, (
		max-width: 1200px
));

@include gridle_register_state (tablet, (
		max-width: 1024px
));

@include gridle_register_state (tablet-middle, (
		max-width: 980px
));

@include gridle_register_state (tablet-small, (
		max-width: 768px
));

@include gridle_register_state (mobile, (
		max-width: 767px
));

@include gridle_register_state (mobile-middle, (
		max-width: 670px
));

@include gridle_register_state (mobile-small, (
		max-width: 480px
));

@include gridle_register_state (mobile-xs-small, (
		max-width: 375px
));

@include gridle_generate_classes (all, grid row pull push no-gutter hide show);

@include gridle_generate_custom_class( ('txt','-','align','-','center','@','%state') ) {
	text-align: center;
	content : "#{gridle_get_current_state_name()}";
}

[class*="no-gutter"] > .row {
	margin-left: -10px !important;
	margin-right: -10px !important;
}

[class*="gr-"] > [class^="row"] {
	margin-left: -10px;
	margin-right: -10px;
}

.gr {
	padding-left: 10px;
	padding-right: 10px;
}

.row-align-center {
	align-items: center;
	justify-content: center;
}

[class*="no-gutter"] > .row-space-xl,
.row-space-xl {
	margin-left: -30px !important;
	margin-right: -30px !important;

	> .gr {
		padding-left: 30px;
		padding-right: 30px;
	}
}
