.b-box {
	&--product-teaser {
		display: flex;
		flex-direction: column;
		justify-content: center;

		width: 100%;
		height: 730px;

		border: 1px solid $color_gray_10;

		padding: 40px;

		@include gridle_state(laptop) {
			height: 650px;

			padding: 30px;
		}

		@include gridle_state(laptop-small) {
			height: 600px;

			padding: 20px;
		}

		@include gridle_state(tablet) {
			height: 540px;
		}

		@include gridle_state(tablet-small) {
			height: 570px;
		}

		@include gridle_state(mobile-middle) {
			height: auto;

			padding: 30px 20px;
		}

		&__pic {
			flex: 1 0 0;

			text-align: center;

			max-height: 322px;

			margin-bottom: 40px;

			@include gridle_state(laptop) {
				max-height: 250px;
			}

			@include gridle_state(laptop-small) {
				max-height: 230px;

				margin-bottom: 20px;
			}

			@include gridle_state(tablet) {
				max-height: 200px;
			}

			.c-image {

				@include gridle_state(laptop) {
					max-height: 250px;
				}

				@include gridle_state(laptop-small) {
					max-height: 230px;
				}

				@include gridle_state(tablet) {
					max-height: 200px;
				}
			}
		}

		&__title {
			font-size: 3rem;
			font-weight: 300;

			border-bottom: 1px solid $color_red;

			margin: 0 0 20px 0;
			padding-bottom: 10px;

			@include gridle_state(laptop-small) {
				margin-bottom: 15px;
			}

			@include gridle_state(mobile-middle) {
				font-size: 2.4rem;
			}
		}

		&__desc {
			padding-bottom: 30px;

			@include gridle_state(laptop-small) {
				padding-bottom: 20px;
			}
		}

		&__btn {

		}
	}
}