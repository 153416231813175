.o-ico {
	&--marker {
		display: block;

		width: 59px;
		height: 67px;

		background-image: url('../img/icons/ico-marker-white.png');
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
	}
}

