.t-head {
	&--primary {
		font-size: 4rem;
		font-weight: 300;

		margin-top: 0;
		margin-bottom: 25px;

		@include gridle_state(laptop) {
			font-size: 3.8rem;
		}

		@include gridle_state(laptop-middle) {
			font-size: 3.6rem;

			margin-bottom: 20px;
		}

		@include gridle_state(laptop-small) {
			font-size: 3.4rem;

			margin-bottom: 15px;
		}

		@include gridle_state(tablet) {
			font-size: 3.2rem;

			line-height: 1.6;
		}

		@include gridle_state(mobile-middle) {
			font-size: 3rem;

			margin-bottom: 10px;
			padding: 0 20px;
		}

		span {
			border-bottom: 1px solid $color_red;

			padding-bottom: 12px;

			@include gridle_state(tablet) {
				padding-bottom: 5px;
			}
		}

		&--alt {
			margin-bottom: 10px;
		}

		&--border {
			border-bottom: 1px solid $color_red;

			padding-bottom: 15px;
		}
	}
}
