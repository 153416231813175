.l-footer {
	&--default {
		min-height: 400px;

		padding-top: 110px;
		padding-bottom: 90px;

		@include gridle_state(laptop) {
			padding-top: 60px;
			padding-bottom: 60px;
		}

		@include gridle_state(laptop-middle) {
			padding-top: 50px;
			padding-bottom: 50px;
		}

		@include gridle_state(laptop-small) {
			padding-top: 40px;
			padding-bottom: 40px;
		}

		@include gridle_state(tablet) {
			padding-top: 30px;
			padding-bottom: 30px;
		}
	}
}
