.b-box {
	&--thumb {
		display: flex;
		justify-content: center;

		position: relative;

		height: 100%;
		width: 25%;

		overflow: hidden;
		cursor: pointer;

		@include gridle_state(mobile-middle) {
			width: 50%;

			height: 135px;
		}

		&:first-child {
			display: none;
		}

		&:hover {

			.b-box--thumb__content {
				opacity: 1;
			}

			.b-box--thumb__pic {
				transform: scale(1.1);
			}
		}

		&__pic {
			width: 100%;
			height: 100%;

			background-size: cover;
			background-repeat: no-repeat;

			transform: scale(1);

			@include transition(transform, .7s);
		}

		&__content {
			display: flex;
			justify-content: center;
			align-items: center;

			position: absolute;

			width: 100%;
			height: 100%;

			top: 0;
			left: 0;

			background: rgba(0, 0, 0, 0.5);

			padding: 30px 50px;

			opacity: 0;

			@include transition(opacity, .7s);

			@include gridle_state(laptop-middle) {
				padding: 30px;
			}

			@include gridle_state(tablet) {
				padding: 15px;
			}
		}

		&__title {
			position: relative;

			font-family: $font_noticia;
			font-size: 2.4rem;
			font-weight: 400;
			font-style: italic;

			text-align: center;

			color: $color_white;

			@include gridle_state(laptop-middle) {
				font-size: 2.2rem;
			}

			@include gridle_state(laptop-small) {
				font-size: 2rem;
			}

			@include gridle_state(tablet) {
				font-size: 1.8rem;
			}

			@include gridle_state(tablet-small) {
				font-size: 1.6rem;
			}

			&:after {
				display: block;

				position: absolute;

				left: 50%;
				bottom: -30px;

				content: url('../img/icons/ico-arrow-3-white-right.png');

				transform: translateX(-50%);

				@include gridle_state(laptop-middle-xs) {
					bottom: -25px;
				}
			}
		}
	}
}