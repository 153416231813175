/* ==========================================================================
   Mixins
   ========================================================================== */

/* 1. Font smoothing
========================================================================== */
/* Better font-rendering on OSX
   https://maximilianhoffmann.com/posts/better-font-rendering-on-osx
*/
@mixin font-smoothing($value: on) {
    @if $value == on {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    } @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}

/* 2. Animation
   ========================================================================== */

@mixin animation($animate...) {
    $max: length($animate);
    $animations: '';

    @for $i from 1 through $max {
        $animations: #{$animations + nth($animate, $i)};

        @if $i < $max {
            $animations: #{$animations + ", "};
        }
    }

    -webkit-animation: $animations;
    -moz-animation:    $animations;
    -o-animation:      $animations;
    animation:         $animations;
}

@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}

/* 3. Get font vw
========================================================================== */

// Function
@function get-vw($target) {
    // 1 vw is equal to 1% of the viewport width
    $vw-context: ($max-breakpoint * .01) * 1px; // 1% viewport width
    @return ($target/$vw-context) * 1vw;
}

// Mixin
@mixin vw($size) {
    font-size: $size;
    font-size: get-vw($size);
}

/* 4. Transition
========================================================================== */

@mixin transition($property, $duration, $ms: false) {
    -webkit-transition: $property $duration ease;
    -moz-transition: $property $duration ease;
    -o-transition: $property $duration ease;
    @if ($ms) {
        -ms-transition: $property $duration ease;
    }
    transition: $property $duration ease;
}
