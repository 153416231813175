.c-list {
	&--tabs {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		list-style: none;

		padding: 0;
		margin: 0 -40px -20px -40px;

		@include gridle_state(laptop-small) {
			margin: 0 -15px -20px -15px;
		}

		@include gridle_state(mobile-middle) {
			justify-content: center;
		}

		&__item {
			padding: 0 40px;

			margin-bottom: 20px;

			@include gridle_state(laptop-small) {
				padding: 0 15px;
			}
		}
	}
}