.b-box {
	&--hero-text {
		display: flex;
		align-items: center;
		justify-content: center;

		height: 100%;

		&__title {
			font-size: 5rem;
			font-weight: 100;
			text-align: center;

			color: $color_white;

			margin: 0;

			@include gridle_state(laptop-middle) {
				font-size: 4.6rem;
			}

			@include gridle_state(laptop-small) {
				font-size: 4.2rem;
			}

			@include gridle_state(tablet) {
				font-size: 3.8rem;
			}

			@include gridle_state(mobile-middle) {
				font-size: 3.4rem;
			}
		}
	}
}