.c-btn {
	&--tab {
		display: flex;
		align-items: center;

		position: relative;

		height: 50px;

		padding: 0 40px;

		cursor: pointer;

		@include transition(opacity, .3s);

		@include gridle_state(laptop-small) {
			padding: 0 25px;
		}

		@include gridle_state(tablet) {
			justify-content: center;

			padding: 0 20px;

			min-width: 205px;
		}

		&:hover {
			opacity: 0.7;
		}

		&.is-active {
			background-color: $color_red;

			&:after {
				display: block;

				position: absolute;

				bottom: -10px;
				left: 50%;

				width: 0;
				height: 0;

				border-left: 10px solid transparent;
				border-right: 10px solid transparent;
				border-top: 10px solid $color_red;

				transform: translateX(-50%);

				content: '';
			}

			.c-btn--tab__label {
				color: $color_white;
			}
		}

		&__label {
			color: $color_black;
		}
	}
}