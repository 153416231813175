.b-box {
	&--maps {
		position: relative;

		width: 100%;
		height: 100%;

		min-height: 600px;

		@include gridle_state(laptop) {
			min-height: 500px;
		}

		@include gridle_state(laptop-middle) {
			min-height: 450px;
		}

		@include gridle_state(tablet) {
			//min-height: 450px;
		}

		&__pic {
			display: none;

			width: 100%;
			height: 100%;

			background-repeat: no-repeat;
			background-size: cover;

			&.is-visible {
				display: block;
			}
		}

		.c-map {
			visibility: hidden;

			width: 100%;
			height: 100%;

			&.is-visible {
				visibility: visible;
			}
		}
	}
}