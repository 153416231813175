.b-box {
	&--404 {
		display: flex;
		align-items: center;
		flex-direction: column;

		width: 100%;
		max-width: 700px;

		margin: 0 auto;

		&__title {
			font-size: 11rem;
			font-weight: 400;
			color: $color_red;

			margin: 30px 0;

			@include gridle_state(tablet) {
				font-size: 10rem;

				margin: 20px 0;
			}

			@include gridle_state(mobile-middle) {
				font-size: 7rem;

				margin: 15px 0;
			}

			@include gridle_state(mobile-small) {
				font-size: 5.5rem;

				margin: 15px 0;
			}
		}

		&__desc {
			font-size: 2.2rem;
			font-weight: 400;
			font-family: $font_noticia;
			font-style: italic;
			text-align: center;

			padding-bottom: 30px;

			@include gridle_state(mobile-middle) {
				font-size: 1.8rem;
			}
		}

		&__btn {
			width: 100%;
			max-width: 500px;

			margin-bottom: 20px;
		}
	}
}