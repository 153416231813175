.b-box {
	&--download {
		display: flex;
		justify-content: center;

		padding: 80px 0;

		margin-top: 80px;

		margin: 80px -10px 0 -10px;

		@include gridle_state(laptop) {
			margin-top: 60px;

			padding: 60px 0;
		}

		@include gridle_state(laptop-middle) {
			margin-top: 50px;

			padding: 50px 0;
		}

		@include gridle_state(laptop-small) {
			margin-top: 40px;

			padding: 40px 0;
		}

		@include gridle_state(tablet) {
			margin-top: 30px;

			padding: 30px 0;
		}

		&--alt {
			padding: 40px 0;
		}

		&__inner {
			display: flex;
			flex-wrap: wrap;

			width: 100%;
			max-width: 1250px;

			padding-left: 20px;
			padding-right: 20px;

			@include gridle_state(tablet-small) {
				flex-direction: column;

				margin: -20px 0;
			}
		}

		&__col {
			display: flex;
			flex-direction: column;

			width: 50%;

			padding-left: 10px;
			padding-right: 10px;

			@include gridle_state(tablet-small) {
				width: 100%;

				padding-top: 20px;
				padding-bottom: 20px;
			}
		}

		&__title {
			font-size: 3rem;
			font-weight: 300;

			color: $color_white;

			margin-top: 0;
			margin-bottom: 20px;

			@include gridle_state(laptop-middle) {
				font-size: 2.6rem;

				margin-bottom: 15px;
			}

			@include gridle_state(laptop-small) {
				font-size: 2.4rem;
			}

			@include gridle_state(tablet) {
				font-size: 2rem;
			}
		}

		&__pic {
			margin-bottom: 20px;
		}

		&__img {
			text-align: center;

			.c-image {

				@include gridle_state(tablet) {
					max-height: 400px;
				}

				@include gridle_state(mobile-middle) {
					max-height: 350px;
				}
			}
		}

		&__items {
			display: flex;
			flex-direction: column;
			justify-content: center;

			height: 100%;
		}

		&__item {
			display: flex;
			align-items: flex-start;

			margin-bottom: 40px;

			@include transition(opacity, .3s);

			&:hover {
				.b-box--download__item__title {
					opacity: 0.4;
				}
			}

			&:last-child {
				margin-bottom: 0;
			}

			&__ico {
				margin-top: 3px;
				margin-right: 15px;
			}

			&__type {
				display: inline-block;

				font-family: $font_noticia;
				font-size: 1.3rem;
				font-style: italic;

				color: $color_black;

				background-color: $color_white;

				padding: 1px 8px;
			}

			&__title {
				font-family: $font_noticia;
				font-size: 2.2rem;
				font-style: italic;
				line-height: 1.2;
				font-weight: 400;

				color: $color_white;

				margin-top: 2px;
				margin-bottom: 0;

				transition: 200ms ease-in-out;

				@include gridle_state(laptop-middle) {
					font-size: 2rem;
				}

				@include gridle_state(laptop-small) {
					font-size: 1.8rem;
				}

				@include gridle_state(mobile-middle) {
					font-size: 1.6rem;
				}
			}
		}
	}
}