.b-box {
	&--tabs {
		display: flex;
		flex-direction: column;

		position: relative;

		&--alt {
			.b-box--tabs__pic {
				width: 33.7%;

				@include gridle_state(tablet) {
					width: 45%;
				}

				@include gridle_state(mobile-middle) {
					height: 100%;
					min-height: 300px;

					width: 100%;
				}
			}

			.b-box--tabs__content {
				background-color: $color_gray_7;

				padding-left: 100px;

				@include gridle_state(laptop-small) {
					padding: 30px;
				}
			}
		}

		&__list {
			padding: 10px 20px 60px 20px;

			@include gridle_state(laptop-middle-xs) {
				padding-bottom: 50px;
			}

			@include gridle_state(laptop-small) {
				padding-bottom: 30px;
			}
		}

		&__item {
			display: none;

			@include gridle_state(mobile-middle) {
				flex-direction: column;
			}

			&.is-active {
				display: flex;

				.b-box--tabs__pic {
					position: relative;

					&:before {

						@include gridle_state(mobile-middle) {
							display: block;

							position: absolute;

							top: 0;
							left: 50%;

							width: 0;
							height: 0;

							border-left: 15px solid transparent;
							border-right: 15px solid transparent;
							border-top: 15px solid $color_white;

							content: '';

							transform: translateX(-50%);
						}
					}
				}

				.b-box--tabs__content {

					&:before {
						display: block;

						position: absolute;

						top: 0;
						left: 245px;

						width: 0;
						height: 0;

						border-left: 15px solid transparent;
						border-right: 15px solid transparent;
						border-top: 15px solid $color_white;

						content: '';

						@include gridle_state(mobile-middle) {
							display: none;
						}
					}
				}
			}
		}

		&__pic {
			width: 100%;
			height: auto;
			min-height: 725px;
			max-width: 680px;

			background-repeat: no-repeat;

			animation: move-pic 1s;

			@include gridle_state(laptop) {
				min-height: 600px;
				background-size: cover;
			}

			@include gridle_state(laptop-middle) {
				min-height: 500px;
			}

			@include gridle_state(laptop-middle-xs) {
				min-height: 450px;
			}

			@include gridle_state(tablet) {
				width: 45%;
			}

			@include gridle_state(mobile-middle) {
				height: 100%;
				min-height: 300px;

				width: 100%;
			}

		}

		&__content {
			position: relative;

			background-color: $color_gray_7;

			width: 100%;
			height: auto;
			min-height: 725px;

			padding: 30px 20px 30px 225px;

			@include gridle_state(laptop) {
				padding-left: 60px;
				min-height: 600px;
			}

			@include gridle_state(laptop-middle) {
				min-height: 500px;

				padding-left: 40px;
			}

			@include gridle_state(laptop-middle-xs) {
				min-height: 450px;

				padding-left: 40px;
			}

			@include gridle_state(tablet) {
				padding-left: 20px;

				width: 55%;
			}

			@include gridle_state(mobile-middle) {
				height: 100%;
				min-height: 390px;

				width: 100%;
			}
		}

		&__desc {
			display: flex;
			flex-direction: column;
			justify-content: center;

			width: 100%;
			height: 100%;
			max-width: 450px;

			animation: move-desc 1s;
		}

		&__head {
			padding-bottom: 30px;

			&__top {
				font-size: 1.6rem;
				font-weight: 400;

				margin-top: 0;
				margin-bottom: 10px;
			}

			&__bottom {
				display: inline-block;
				font-size: 2.4rem;
				font-weight: 300;

				border-top: 1px solid $color_white;

				padding-top: 10px;
				margin-top: 0;
				margin-bottom: 0;
			}
		}

		&__img {
			position: absolute;

			bottom: 0;
			left: 650px;

			z-index: 1;

			transform: translateX(-50%);

			@include gridle_state(desktop) {
				left: calc(50% - 300px);
			}

			@include gridle_state(laptop) {
				left: 37%;
			}

			@include gridle_state(laptop-middle-xs) {
				left: 42.5%;
			}

			@include gridle_state(laptop-small) {
				left: 41.5%;
			}

			@include gridle_state(tablet) {
				left: 35.5%;
			}

			@include gridle_state(tablet-small) {
				left: 34%;
			}

			@include gridle_state(mobile-middle) {
				display: none;
			}

			img {
				@include gridle_state(laptop) {
					max-height: 650px;
				}

				@include gridle_state(laptop-middle) {
					max-height: 600px;
				}

				@include gridle_state(laptop-middle-xs) {
					max-height: 550px;
				}

				@include gridle_state(tablet) {
					max-height: 500px;
				}
			}
		}

		&__bar {
			display: flex;
			justify-content: center;

			width: 100%;
			padding: 65px 0;

			@include gridle_state(mobile-middle) {
				padding: 40px 20px;
			}
		}
	}
}

@keyframes move-pic {
	from {
		transform: translateX(-100%) scale(0);
	}
	to {
		transform: translateX(0) scale(1);
	}
}

@keyframes move-desc {
	from {
		transform: translateX(200px) scale(0.5);
	}
	to {
		transform: translateX(0) scale(1);
	}
}