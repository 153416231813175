.l-sec {
	&--primary {
		padding: 75px 0;

		@include gridle_state(laptop) {
			padding-top: 60px;
			padding-bottom: 60px;
		}

		@include gridle_state(laptop-middle) {
			padding-top: 50px;
			padding-bottom: 50px;
		}

		@include gridle_state(laptop-small) {
			padding-top: 40px;
			padding-bottom: 40px;
		}

		@include gridle_state(tablet) {
			padding-top: 30px;
			padding-bottom: 30px;
		}

		&--small {
			@include gridle_state(laptop) {
				padding-top: 50px;
				padding-bottom: 50px;
			}

			@include gridle_state(laptop-middle) {
				padding-top: 40px;
				padding-bottom: 40px;
			}

			@include gridle_state(tablet) {
				padding-top: 30px;
				padding-bottom: 30px;
			}
		}

		&--alt {
			padding-bottom: 0;
		}

		&--bg {
			display: flex;
			justify-content: center;

			min-height: 600px;

			margin-top: 30px;

			background-color: $color_gray_7;
			background-repeat: no-repeat;
			background-position: 50% 50%;

			@include gridle_state(laptop) {
				min-height: 500px;
			}

			@include gridle_state(laptop-middle) {
				min-height: 450px;

				margin-top: 20px;
			}

			@include gridle_state(tablet) {
				min-height: 400px;
			}

			@include gridle_state(tablet-small) {
				min-height: 350px;
			}
		}
	}
}
