.c-list {
	&--address {
		display: flex;
		flex-wrap: wrap;
		//justify-content: space-between;
		align-items: stretch;

		list-style: none;

		padding: 20px 0 0 0;
		margin: 0 -25px -40px -25px;

		@include gridle_state(tablet-small) {
			margin: 0 -20px -40px -20px;

			padding-bottom: 10px;
		}

		@include gridle_state(mobile-middle) {
			margin: 0 -20px -30px -20px;
		}

		&__item {
			padding: 0 25px;
			margin-bottom: 40px;

			@include gridle_state(tablet-small) {
				padding: 0 20px;
			}

			@include gridle_state(mobile-middle) {
				margin-bottom: 30px;
			}
		}
	}
}

.c-list {
	&--address {
		$that: &;

		&--i2 {
			#{$that} {
				&__item {
					width: (100 / 2) * 1%;

					@include gridle_state(tablet) {
						width: (100 / 3) * 1%;
					}

					@include gridle_state(tablet-small) {
						width: (100 / 2) * 1%;
					}

					@include gridle_state(mobile-small) {
						width: (100 / 1) * 1%;
					}
				}
			}
		}

		&--i3 {
			#{$that} {
				&__item {
					width: (100 / 3) * 1%;

					@include gridle_state(tablet) {
						width: (100 / 3) * 1%;
					}

					@include gridle_state(tablet-small) {
						width: (100 / 2) * 1%;
					}

					@include gridle_state(mobile-small) {
						width: (100 / 1) * 1%;
					}
				}
			}
		}

		&--i4 {
			#{$that} {
				&__item {
					width: (100 / 4) * 1%;

					@include gridle_state(tablet) {
						width: (100 / 2) * 1%;
					}

					@include gridle_state(tablet-small) {
						width: (100 / 2) * 1%;
					}

					@include gridle_state(mobile-small) {
						width: (100 / 1) * 1%;
					}
				}
			}
		}
	}
}
