.o-ico {
	&--loop {

		&,
		svg {
			display: block;

			width: 17px;
			height: 17px;

			fill: $color_white;
		}

		&--alt {

			&,
			svg {
				width: 34px;
				height: 34px;

				@include gridle_state(mobile-middle) {
					width: 25px;
					height: 25px;
				}

				@include gridle_state(mobile-small) {
					width: 20px;
					height: 20px;
				}
			}
		}
	}
}