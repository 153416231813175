.c-nav {
	&--small {

		@include gridle_state(tablet) {
			padding-top: 10px;
		}

		&__list {
			display: flex;

			list-style: none;

			padding: 0;
			margin: 0 -10px;

			@include gridle_state(tablet) {
				flex-direction: column;
				align-items: flex-start;

				position: relative;

				left: 50%;

				width: 50%;

				margin: 0;
			}

			@include gridle_state(tablet-small) {
				width: 100%;
			}

			@include gridle_state(mobile-middle) {
				align-items: center;
				width: 100%;
				left: auto;

				padding: 5px 20px 15px 20px;

				margin: -10px 0;
			}
		}

		&__item {
			padding: 0 10px;

			@include gridle_state(tablet) {
				padding: 10px;
			}

			@include gridle_state(mobile-middle) {
				padding: 10px 0;
			}

			&.is-active {

				.c-label {

					@include gridle_state(tablet) {
						color: $color_red;
					}
				}
			}

			.c-label {
				font-size: 1.5rem;
				font-weight: 300;

				color: $color_white;

				opacity: 1;

				@include transition(opacity, .3s);

				&:hover {
					opacity: 0.5;
				}

				@include gridle_state(tablet) {
					font-size: 1.6rem;
					font-weight: 400;

					color: $color_black;
				}
			}
		}
	}
}
