.b-box {
	&--video {
		display: block;

		position: relative;

		width: 100%;
		min-height: 400px;

		background-repeat: no-repeat;
		background-size: cover;

		cursor: pointer;

		overflow: hidden;

		@include gridle_state(laptop-middle) {
			min-height: 350px;
		}

		&:hover {

			.b-box--video__pic {
				transform: scale(1.05);
			}

			.b-box--video__desc {
				bottom: -100%;
			}

			&:after {
				top: 50%;
			}
		}

		&:after {
			display: block;
			position: absolute;

			top: 50%;
			left: 50%;

			content: url('../img/icons/ico-play.png');

			transform: translate(-50%, -50%);

			@include transition(all, .6s);
		}

		&--alt {

			&:after {
				top: calc(50% - 40px);
			}
		}

		&__pic {
			width: 100%;
			height: 400px;

			background-size: cover;
			background-repeat: no-repeat;

			transform: scale(1);

			@include transition(all, .5s);

			@include gridle_state(laptop-middle) {
				height: 350px;
			}
		}

		&__desc {
			display: block;

			position: absolute;

			bottom: 0;
			left: 0;

			width: 100%;

			font-family: $font_noticia;
			font-size: 1.8rem;
			font-style: italic;
			color: $color_white;

			min-height: 110px;

			background-color: $color_blue;

			margin: 0;
			padding: 30px;

			@include transition(all, .6s);
		}
	}
}