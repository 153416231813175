.b-box {
	&--address-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		padding: 80px 60px 70px 60px;

		@include gridle_state(laptop) {
			padding: 80px 30px 70px 30px;
		}

		@include gridle_state(tablet) {
			padding: 60px 30px;
		}

		@include gridle_state(mobile-middle) {
			padding: 30px 20px;
		}
	}
}