.b-box {
	&--hero {
		position: relative;

		height: auto;
		min-height: calc(100vh - 100px);

		@include gridle_state(laptop) {
			//min-height: calc(100vh - 100px);
		}

		@include gridle_state(laptop-middle) {
			//min-height: calc(100vh - 150px);
		}

		@include gridle_state(laptop-middle-xs) {
			//height: auto;
			//min-height: 660px;
		}

		@include gridle_state(laptop-small) {
			//min-height: 600px;
		}

		@include gridle_state(tablet) {
			min-height: 550px;
		}

		@include gridle_state(mobile-middle) {
			min-height: 690px;
		}

		&__item {
			display: flex;
			justify-content: center;

			position: absolute;

			top: 0;
			left: 0;

			width: 100%;
			height: calc(100% - 100px);

			background-size: cover;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			//background-attachment: fixed;

			display: none;

			@include gridle_state(laptop-middle) {
				height: calc(100% - 80px);
			}

			@include gridle_state(laptop-middle-xs) {
				height: calc(100% - 60px);
			}

			@include gridle_state(tablet) {
				height: calc(100% - 30px);
			}

			@include gridle_state(mobile-middle) {
				height: 100%;
			}

			&:after {
				display: block;
				position: absolute;

				top: 0;
				left: 0;

				width: 100%;
				height: 100%;

				background: rgba(0, 0, 0, 0.2);

				content: '';
			}

			&.is-active {
				display: flex;
				opacity: 1;
				visibility: visible;
			}
		}

		&__content {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			position: relative;

			width: 100%;
			height: calc(100% - 115px);
			max-width: 700px;

			padding: 0 20px;

			z-index: 1;

			@include gridle_state(laptop) {
				height: calc(100% - 100px);
			}

			@include gridle_state(laptop-middle) {
				height: calc(100% - 110px);
			}

			@include gridle_state(laptop-middle-xs) {
				height: calc(100% - 110px);
			}

			@include gridle_state(laptop-small) {
				height: calc(100% - 100px);
			}

			@include gridle_state(tablet) {
				height: calc(100% - 115px);
			}

			@include gridle_state(mobile-middle) {
				height: calc(100% - 270px);
			}
		}

		&__thumbs {
			display: flex;
			flex-wrap: wrap;

			position: absolute;

			bottom: 0;
			left: 0;

			width: 100%;
			height: 215px;

			z-index: 1;

			@include gridle_state(laptop) {
				height: 200px;
			}

			@include gridle_state(laptop-middle) {
				height: 190px;
			}

			@include gridle_state(laptop-middle-xs) {
				height: 180px;
			}

			@include gridle_state(laptop-middle-xs) {
				height: 170px;
			}

			@include gridle_state(laptop-small) {
				height: 160px;
			}

			@include gridle_state(tablet) {
				height: 145px;
			}

			@include gridle_state(mobile-middle) {
				height: 270px;
			}
		}
	}
}