.b-box {
	&--contact {
		display: flex;
		justify-content: flex-end;

		width: 100%;
		height: 100%;

		padding: 80px 60px 70px 20px;

		@include gridle_state(laptop) {
			padding: 60px 30px 60px 20px;
		}

		@include gridle_state(laptop-middle) {
			justify-content: center;

			padding: 50px 20px 50px 20px;
		}

		@include gridle_state(tablet) {
			padding: 30px 20px 30px 20px;
		}

		&--alt {
			padding: 80px 30px 70px 30px;

			@include gridle_state(laptop) {
				padding: 80px 15px 70px 15px;
			}

			@include gridle_state(laptop-middle) {
				padding: 50px 20px 50px 20px;
			}

			@include gridle_state(tablet) {
				padding: 30px 20px 30px 20px;
			}
		}

		&__content {
			width: 100%;
			max-width: 544px;

			@include gridle_state(tablet) {
				max-width: 650px;
			}
		}

		&__form {
			padding-top: 15px;

			&__btn {
				display: flex;
				justify-content: flex-end;

				padding-top: 10px;
			}
		}
	}
}