/**
 * Apply a natural box layout model to all elements
 */

*, *:before, *:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

/* Base
   ========================================================================== */
html {
	-webkit-font-smoothing: antialiased;

	font-size: 62.5%; /* 10px */
}

body {
	min-width: 320px;
	max-width: 2560px;

	font-family: $font_normal;
	font-size: 1.6rem;
	font-weight: 400;
	line-height: 1.4;

	color: $color_black;
	background: $color_white;

	padding-top: 100px;

	overflow-x: hidden;

	margin: 0 auto;

	@include gridle_state(tablet) {
		padding-top: 0;
	}

	@include gridle_state(mobile-middle) {
		font-size: 1.4rem;
	}

	&.l-body-lang {
		padding-top: 0;
	}
}

/* Global Typography
   ========================================================================== */

a {
	text-decoration: none;

	color: $color_gray;

	@include transition(color, .3s);

	&:hover {
		text-decoration: none;
		//opacity: .7;
	}

	&:focus {
		outline: none;
	}
}

img {
	max-width: 100%;
}

button {
	outline: none;
}

figure {
	margin: 0;
}

/* Correct the text style of placeholders in Chrome, Edge, and Safari.
   ========================================================================== */

::-webkit-input-placeholder {
	color: inherit;
	opacity: 1;
}
