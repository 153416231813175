.f-control {
    &--hero-search {
		color: $color_white;
		font-size: 2.4rem;

		padding: 10px 15px;

		width: 100%;
		height: 55px;

		background: transparent;
		border: 1px solid $color_white;

		@include gridle_state(tablet) {
			height: 45px;
		}
    }
}