/* Colors
========================================================================== */
$color_black: #000000;
$color_white: #ffffff;
$color_dark: #242424;
$color_light: #c0c0c0;
$color_gray: #dcdcdc;
$color_gray_2: #404040;
$color_gray_3: #b6b5b5;
$color_gray_4: #eeeeee;
$color_gray_5: #cdcdcd;
$color_gray_6: #b4b4b4;
$color_gray_7: #e8e8e8;
$color_gray_8: #cfcdcd;
$color_gray_9: #999999;
$color_gray_10: #c2c2c2;
$color_gray_11: #d7d7d7;
$color_gray_12: #f3f1f1;
$color_gray_13: #ebeaea;
$color_blue: #0067ab;
$color_blue_2: #015593;
$color_red: #ed1c24;
$color_yellow: #fece17;
$color_green: #00b04b;

/* Sizes
========================================================================== */
$size_inner: 1210px;

$max-breakpoint: 1920;


/* Margins
========================================================================== */

$margin_default: 20px;
$padding_default: 20px;

/* Fonts
========================================================================== */

$font_roboto: 'Roboto';
$font_noticia: 'Noticia Text', serif;

$font_normal: $font_roboto, Arial, sans-serif;
