.c-carousel {
	&--main {
		position: relative;

		.c-carousel__item {
			outline: none;
		}

		.c-carousel__dots {
			position: absolute;

			top: -70px;
			right: 0;

			z-index: 10;

			@include gridle_state(laptop-middle-xs) {
				top: -40px;
			}

			@include gridle_state(tablet) {
				top: -35px;
			}

			@include gridle_state(mobile-middle) {
				top: -30px;
				right: auto;
				left: 50%;

				transform: translateX(-50%);
			}

			&__list {
				display: flex;


				list-style: none;

				padding: 0;
				margin: 0;

				li {
					display: block;

					padding: 0 4px;

					&.slick-active {

						button {
							background: $color_blue;
						}
					}

					button {
						width: 9px;
						height: 9px;

						text-indent: -777em;

						background: $color_red;
						border: 0;
						border-radius: 50%;
						outline: none;

						padding: 0;
						cursor: pointer;
					}
				}
			}
		}

		.c-carousel__btn {
			position: absolute;

			top: 380px;
			right: 0;

			margin: 0 -8px;

			z-index: 10;

			@include gridle_state(laptop) {
				top: 340px;
			}

			@include gridle_state(laptop-middle) {
				top: 300px;
			}

			@include gridle_state(tablet) {
				top: 270px;
			}

			@include gridle_state(mobile-middle) {
				display: flex;
				justify-content: space-between;

				top: 250px;
				right: auto;
				left: 0;

				width: 100%;

				margin: 0;
			}
		}
	}
}