.c-list {
	&--footer {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		list-style: none;

		padding: 0;
		margin: 0 -40px -30px -40px;

		@include gridle_state(tablet-small) {
			margin: 0 -20px -30px -20px;
		}

		@include gridle_state(mobile-small) {
			margin: 0 -20px -20px -20px;
		}

		&__item {
			padding: 0 40px;

			margin-bottom: 30px;

			@include gridle_state(tablet) {
				width: 50%;
			}

			@include gridle_state(tablet-small) {
				padding: 0 20px;
			}

			@include gridle_state(mobile-small) {
				width: 100%;

				margin-bottom: 20px;
			}
		}
	}
}
