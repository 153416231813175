.o-ico {
	&--social {
		display: block;

		width: 35px;
		height: 35px;

		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;

		&--yt {
			background-image: url('../img/icons/ico-yt.png');
		}

		&--linkedin {
			background-image: url('../img/icons/ico-linkedin.png');
		}

		&--facebook {
			background-image: url('../img/icons/ico-facebook.png');
		}

		&--twitter {
			background-image: url('../img/icons/ico-twitter.png');
		}
	}
}

