.b-box {
	&--shortcut {
		display: flex;
		flex-direction: column;

		&__pic {
			display: flex;
			justify-content: center;
			align-items: flex-end;
			height: 100px;
		}

		&__title {
			flex: 1 0 0;

			font-family: $font_noticia;
			font-size: 2.2rem;
			font-weight: 400;
			font-style: italic;

			margin-top: 30px;
			margin-bottom: 0;

			@include gridle_state(laptop-middle) {
				font-size: 2rem;

				margin-top: 20px;
			}

			@include gridle_state(mobile-middle) {
				font-size: 1.8rem;

				margin-top: 15px;
			}
		}
	}
}