.b-box {
	&--product {
		display: flex;

		position: relative;

		margin: 0 -20px;

		@include gridle_state(mobile-middle) {
			margin: 0;
		}

		&__col {
			position: relative;

			width: 50%;

			padding: 0 20px;

			@include gridle_state(mobile-middle) {
				width: 100%;

				padding: 0;
			}

			&--spec {
				max-height: 225px;

				@include gridle_state(tablet) {
					max-height: 100%;
				}

				@include gridle_state(mobile-middle) {
					display: none;
				}
			}
		}

		&__btn {
			padding-top: 30px;

			@include gridle_state(laptop-middle) {
				padding-top: 15px;
			}

			@include gridle_state(tablet) {
				padding-top: 10px;
			}
		}

		&__pic {
			position: absolute;

			max-height: 780px;
			width: 283px;

			bottom: -70px;

			@include gridle_state(laptop) {
				max-height: 680px;

				width: 250px;

				bottom: -40px;
			}

			@include gridle_state(laptop-middle) {
				max-height: 630px;
				width: 230px;
			}

			@include gridle_state(laptop-small) {
				max-height: 580px;
				width: 200px;
			}

			@include gridle_state(tablet) {
				display: none;
			}
		}

		&__spec {
			position: absolute;
			left: 300px;

			top: -40px;

			@include gridle_state(laptop) {
				left: 268px;
			}

			@include gridle_state(laptop-middle) {
				left: 247px;
				top: -20px;
			}

			@include gridle_state(laptop-small) {
				left: 218px;
			}

			@include gridle_state(tablet) {
				position: static;

				top: 0;
				left: auto;
			}

			&__item {
				margin-bottom: 15px;
			}

			&__desc {
				font-size: 1.6rem;
				color: $color_white;
				line-height: 1.2;

				padding-left: 20px;
				margin: 0 0 10px 0;
			}

			&__green {
				display: flex;
				justify-content: center;
				align-items: center;

				position: relative;

				height: 50px;
				width: 100px;

				font-size: 3rem;
				font-weight: 700;
				color: $color_white;

				background-color: $color_green;

				padding: 5px 10px;

				@include gridle_state(laptop-middle) {
					height: 46px;
					width: 75px;

					font-size: 2.6rem;
				}

				&:after {
					display: block;

					position: absolute;

					top: 0;
					right: -25px;

					width: 0;
					height: 0;
					border-top: 25px solid transparent;
					border-bottom: 25px solid transparent;

					border-left: 25px solid $color_green;

					content: '';

					@include gridle_state(laptop-middle) {
						border-top: 23px solid transparent;
						border-bottom: 23px solid transparent;
					}
				}
			}
		}

	}
}