.o-ico {
	&--file {
		display: block;

		width: 42px;
		height: 43px;

		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
		background-image: url('../img/icons/ico-file.png');
	}
}

