.c-carousel {
	&--gallery {
		position: relative;

		min-height: 400px;

		margin-top: 60px;

		@include gridle_state(laptop-middle) {
			min-height: 340px;

			margin-top: 40px;
		}

		.c-carousel__items {

		}

		.slick-list {
			margin: 0 -15px;

			@include gridle_state(tablet-small) {
				margin: 0;
			}
		}

		.c-carousel__item {
			outline: none;

			margin: 0 15px;

			@include gridle_state(tablet-small) {
				margin: 0;
				padding: 0 20px;
			}
		}

		.c-carousel__btn {
			position: absolute;

			top: calc(50% - 20px);

			transform: translateY(-50%);

			z-index: 10;

			@include gridle_state(laptop-middle) {
				top: calc(50% - 10px);
			}

			.c-btn {
				margin: 0;
			}

			&--prev {
				left: 0;
			}

			&--next {
				right: 0;
			}
		}
	}
}