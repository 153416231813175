.c-logo {
    &--main {
		display: flex;
		justify-content: center;
		align-items: center;

		position: absolute;

		top: 0;
		left: 20px;

		width: 140px;
		height: 140px;

		background-color: $color_white;

		z-index: 50;

		@include gridle_state(tablet) {
			width: 140px;
			height: 140px;
		}

		@include gridle_state(tablet-small) {
			width: 120px;
			height: 120px;
		}

		@include gridle_state(mobile-middle) {
			width: 100px;
			height: 100px;
		}

		@include gridle_state(mobile-small) {
			width: 80px;
			height: 80px;
		}

		&--alt {
			left: 50%;
			transform: translateX(-50%);
		}

		svg {
			width: 107px;
			height: 107px;

			@include transition(all, .3s);

			@include gridle_state(laptop-small) {
				width: 90px;
				height: 90px;
			}

			@include gridle_state(tablet) {
				width: 107px;
				height: 107px;
			}

			@include gridle_state(tablet-small) {
				width: 90px;
				height: 90px;
			}

			@include gridle_state(mobile-middle) {
				width: 70px;
				height: 70px;
			}

			@include gridle_state(mobile-small) {
				width: 50px;
				height: 50px;
			}
		}

		&:hover {
			opacity: 1;

			svg {
				opacity: 0.7;
			}
		}
	}
}