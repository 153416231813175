
/* Head outer
   ========================================================================== */

.head-outer {
    @extend %helper-clear-margins;

    margin: $margin_default 0;
}

/* Head
   ========================================================================== */

.head {
    font-weight: 300;
}

.t-head-outer {
	@extend %helper-clear-margins;

	margin: $margin_default 0;

	&--alt {
		padding-bottom: 40px;

		@include gridle_state(tablet) {
			padding-bottom: 30px;
		}
	}

	.c-date {
		@extend %helper-clear-margins;

		font-family: $font_noticia;
		font-size: 2.2rem;
		font-weight: 400;
		font-style: italic;

		margin-top: 0;
		margin-bottom: 20px;

		@include gridle_state(mobile-middle) {
			font-size: 2rem;

			margin-bottom: 10px;
		}
	}
}