.c-list {
	&--lang {
		display: flex;
		position: relative;

		list-style: none;

		padding: 0 50px;
		margin: 0;

		@include gridle_state(laptop) {
			padding: 0 30px;
		}

		@include gridle_state(laptop-middle) {
			padding-right: 15px;
		}

		@include gridle_state(laptop-small) {
			padding: 0 10px;
		}

		@include gridle_state(tablet) {
			display: block;

			padding-top: 5px;
			padding-bottom: 20px;
		}

		&:before {
			display: block;

			position: absolute;

			top: 2px;
			left: 20px;

			content: url('../img/icons/ico-arrow-1-down.png');

			@include gridle_state(laptop) {
				left: 10px;
			}

			@include gridle_state(laptop-small) {
				display: none;
			}
		}

		&__item {

			@include gridle_state(tablet) {
				display: inline-block;
				width: 40px;
				height: 40px;
				line-height: 38px;
				font-size: 1.6rem;
				font-weight: 700;
				border-radius: 50%;
			}
			&.is-active {

				@include gridle_state(tablet) {
					display: inline-block;

					background: $color_blue;
				}

				.c-label {

					@include gridle_state(tablet) {
						color: $color_white;
					}
				}
			}

			.c-label {
				font-weight: 700;

				opacity: 1;
			}
		}
	}
}
