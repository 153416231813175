.c-list {
	&--shortcuts {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		list-style: none;

		padding: 20px 0;
		margin: 0 -75px -20px -75px;

		@include gridle_state(laptop) {
			margin: 0 -60px -20px -60px;
		}

		@include gridle_state(laptop-middle) {
			margin: 0 -45px -20px -45px;
			padding-top: 0;
		}

		@include gridle_state(tablet) {
			margin: 0 -30px -20px -30px;
		}

		@include gridle_state(mobile-middle) {
			margin: 0 -20px -20px -20px;
		}

		@include gridle_state(mobile-small) {
			margin: 0 -30px -10px -30px;
		}

		&__item {
			padding: 0 75px;

			margin-bottom: 20px;

			@include gridle_state(laptop) {
				padding: 0 60px;
			}

			@include gridle_state(laptop-middle) {
				padding: 0 45px;
			}

			@include gridle_state(tablet) {
				padding: 0 30px;
			}

			@include gridle_state(mobile-middle) {
				padding: 0 20px;
			}

			@include gridle_state(mobile-small) {
				margin-bottom: 10px;
			}
		}
	}
}

