.c-ico {
	&--text {
		display: flex;
		align-items: center;

		&__icon {
			padding-right: 30px;

			@include gridle_state(tablet) {
				padding-right: 20px;
			}
		}

		&__main {

			p {
				margin-top: 0;
				margin-bottom: 2px;
			}

			a {
				color: $color_black;
			}
		}
	}
}