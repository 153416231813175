.c-nav {
	&--footer-main {

		&__list {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;

			list-style: none;

			padding: 0;
			margin: 0 -15px 0 0;
		}

		&__item {
			position: relative;

			padding: 1px 15px;

			&:before {
				display: block;

				position: absolute;

				left: 0;
				top: 50%;

				content: url('../img/icons/ico-arrow-1-white-right.png');

				transform: translateY(-50%);
			}

			.c-label {
				font-size: 1.4rem;
				font-weight: 300;

				color: $color_white;

				opacity: 1;

				@include transition(opacity, .3s);

				&:hover {
					opacity: 0.5;
				}
			}
		}
	}
}
