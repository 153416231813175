/* Text weight
   ========================================================================== */

.u-400 {
	font-weight: 400 !important;
}

.u-700 {
	font-weight: 700 !important;
}


/* Text aligned
   ========================================================================== */

.u-text-left {
	text-align: left;
}

.u-text-center {
	text-align: center;
}

.u-text-right {
	text-align: right;
}

.u-text-uppercase {
	text-transform: uppercase;
}

.u-text-italic {
	font-style: italic;
}

.u-pull-right {
	float: right;
}

.u-pull-left {
	float: left;
}

/* Backgrounds
   ========================================================================== */

.u-bg-1 {
	background-color: $color_gray_2;
}

.u-bg-2 {
	background-color: $color_blue;
}

.u-bg-3 {
	background-color: $color_gray_3;
}

.u-bg-4 {
	background-color: $color_gray_4;
}

.u-bg-5 {
	background-color: $color_white;
}

.u-bg-6 {
	background-color: $color_yellow;
}

.u-bg-7 {
	background-color: $color_gray_6;
}

/* Backgrounds sections
   ========================================================================== */

.u-bg-sec-1 {

}

/* Colors
   ========================================================================== */

.u-color-1 {
	color: $color_white;
}

.u-color-2 {
	color: $color_blue_2;
}

.u-color-3 {
	color: $color_red;
}

.u-color-4 {
	color: $color_yellow;
}
