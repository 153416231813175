.c-nav {
	&--main {
		$this: &;

		height: 100%;

		&__list {
			display: flex;

			height: 100%;

			list-style: none;

			padding: 0;
			margin: 0 -30px;

			@include gridle_state(laptop-middle-xs) {
				margin: 0 -25px;
			}

			@include gridle_state(laptop-small) {
				margin: 0 -10px;
			}

			@include gridle_state(tablet) {
				display: block;

				margin: 0;
			}
		}

		&__item {
			position: relative;

			padding: 0 30px;

			@include gridle_state(laptop-middle-xs) {
				padding: 0 25px;
			}

			@include gridle_state(laptop-small) {
				padding: 0 15px;
			}

			@include gridle_state(tablet) {
				display: block;

				background-color: $color_gray_9;

				padding: 0;
				margin-bottom: 1px;
			}

			&--submenu {

				&:after {

					@include gridle_state(tablet) {
						display: block;

						position: absolute;

						top: 13px;
						right: 20px;

						content: url('../img/icons/ico-arrow-2-white-down.png');

						transform: rotate(0);

						@include transition(all, .3s);
					}
				}

				&.is-active {

					&:after {

						@include gridle_state(tablet) {
							transform: rotate(-180deg);
						}
					}
				}
			}

			&.is-active {

				& > .c-nav--main__sub {

					@include gridle_state(tablet) {
						display: block;

						opacity: 1;
						visibility: visible;
					}
				}

				& > .c-label {

					@include gridle_state(tablet) {
						background-color: $color_red;
					}
				}
			}

			&:hover {

				@include gridle_state(tablet) {

				}

				& > .c-nav--main__sub {
					opacity: 1;
					visibility: visible;
				}

				& > .c-label {
					border-bottom: 4px solid $color_red;

					@include gridle_state(tablet) {
						border: 0;

						background-color: $color_red;
					}
				}
			}

			.c-label {
				display: block;

				font-size: 1.7rem;
				font-weight: 700;
				text-transform: uppercase;

				color: $color_white;

				height: 100%;
				width: 100%;

				opacity: 1;
				cursor: pointer;

				@include transition(all, .3s);

				@include gridle_state(laptop-small) {
					font-size: 1.6rem;
				}

				@include gridle_state(tablet) {
					display: inline-block;

					position: relative;

					font-size: 1.8rem;
					font-weight: 400;
					line-height: 1;
					text-align: left;

					padding: 15px 30px;
				}

				&:after {

					@include gridle_state(tablet) {
						position: absolute;
						left: 0;
						bottom: 0;
						content: '';
						width: 100%;
						height: 2px;
						background: $color_blue;

						opacity: 0;

						transform: translateY(0);

						@include transition(all, .3s);
					}
				}
			}

		}

		&__sub {
			@extend %helper-reset-list;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;

			position: absolute;

			top: 45px;
			left: 50%;

			min-width: 200px;

			z-index: 100;

			padding: 35px 0;

			background: $color_gray;

			opacity: 0;
			visibility: hidden;

			transform: translateX(-50%);

			transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;

			@include gridle_state(tablet) {
				display: none;

				position: relative;

				top: 0;
				left: 0;

				width: 100%;
				min-width: 0;

				transform: translateX(0);

				padding: 0;
			}

			&--large {
				min-width: 380px;

				.c-nav--main__sub__item {
					text-align: left;
				}
			}

			&__item {
				display: block;

				width: 100%;

				text-align: center;

				padding: 10px 35px;

				@include gridle_state(tablet) {
					padding: 0;

					background-color: transparent;
				}

				&.is-active {

					& > .c-label {

						@include gridle_state(tablet) {
							background-color: $color_red;
						}
					}
				}

				&--alt {

					@include gridle_state(tablet) {
						position: relative;
					}

					&:hover {

						&:after {
							@include gridle_state(tablet) {
								content: url('../img/icons/ico-arrow-2-white-down.png');
							}
						}
					}

					&.is-active {

						.c-nav--main__sub-second {

							@include gridle_state(tablet) {
								display: block;
							}
						}

						&:after {
							@include gridle_state(tablet) {
								content: url('../img/icons/ico-arrow-2-white-up.png');
							}
						}
					}

					&:after {

						@include gridle_state(tablet) {
							display: block;

							position: absolute;

							top: 13px;
							right: 20px;

							content: url('../img/icons/ico-arrow-2-black-down.png');
						}
					}
				}

				&:hover {

					& > .c-label {
						color: $color_white;

						@include gridle_state(tablet) {
							background-color: $color_red;
						}
					}

					& > .c-nav--main__sub-second {
						opacity: 1;
						visibility: visible;
					}
				}

				& > .c-label {
					display: block;

					position: relative;

					font-size: 1.6rem;
					font-weight: 400;
					color: $color_black;

					text-transform: capitalize;

					@include gridle_state(tablet) {
						padding: 15px 30px;
					}
				}

				&--alt {

					&:hover {
						& > .c-label {

							&:after {
								content: url('../img/icons/ico-arrow-1-white-right.png');
							}
						}
					}

					& > .c-label {

						&:hover {
							& > .c-label {

								@include gridle_state(tablet) {
									padding: 15px 30px;
								}

								&:after {
									content: url('../img/icons/ico-arrow-1-white-right.png');
								}
							}
						}

						&:after {
							display: block;

							position: absolute;

							right: 0;
							top: 50%;

							content: url('../img/icons/ico-arrow-1-black-right.png');

							transform: translateY(-50%);

							@include transition(all, .3s);
						}
					}
				}
			}
		}

		&__sub-second {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;

			position: absolute;

			top: 0;
			right: -420px;

			width: 420px;
			height: 100%;

			background-color: $color_white;

			opacity: 0;
			visibility: hidden;

			padding: 35px 0;

			@include gridle_state(tablet) {
				display: none;
				position: relative;

				right: auto;
				width: 100%;

				padding: 0;

				opacity: 1;
				visibility: visible;
			}

			&__item {
				display: block;

				width: 100%;

				padding: 15px 35px;

				&:hover {

					& > .c-label {
						font-weight: 700;
					}
				}

				.c-label {
					display: block;

					font-size: 1.6rem;
					font-weight: 400;

					color: $color_black;

					text-transform: capitalize;

					@include transition(all, .3s);

					@include gridle_state(tablet) {
						padding: 0;
					}
				}
			}
		}
	}
}
