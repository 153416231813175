/* Ico
   ========================================================================== */

svg:not(:root) {
	overflow: visible;
}

.o-ico {
	display: block;
}

