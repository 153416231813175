.c-list {
	&--text {
		list-style: none;

		padding: 20px 0 0 15px;
		margin: 0;

		@include gridle_state(mobile-middle) {
			justify-content: center;
		}

		&__item {
			position: relative;

			font-family: $font_noticia;
			font-size: 1.8rem;
			font-style: italic;
			text-indent: -15px;

			margin-bottom: 25px;

			&:before {
				display: inline-block;

				position: relative;

				width: 9px;
				height: 9px;

				top: -2px;

				background-color: $color_red;
				border-radius: 50%;

				content: '';

				margin-right: 5px;
			}
		}
	}
}