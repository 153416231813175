.b-box {
	&--country {
		display: flex;
		align-items: center;

		&:hover {
			.b-box--country__flag {
				transform: scale(1.05) rotate(12deg);
			}

			.b-box--country__name {
				color: $color_red;
			}
		}

		&__flag {
			margin-right: 10px;

			transform: scale(1) rotate(0);

			@include transition(all, .3s);
		}

		&__name {
			font-size: 1.4rem;
			font-family: $font_noticia;
			font-style: italic;
			color: $color_blue;
			text-transform: capitalize;

			margin: 0;

			@include transition(all, .5s);
		}
	}
}