.c-nav {
	&--footer {
		padding-bottom: 10px;

		@include gridle_state(tablet-small) {
			padding-bottom: 0;
		}

		&--small {
			padding-bottom: 0;

			.c-nav--footer__item .c-label {
				font-size: 1.4rem;

				@include gridle_state(mobile-middle) {
					font-size: 1.2rem;
				}
			}
		}

		&__list {
			display: flex;
			flex-wrap: wrap;

			list-style: none;

			padding: 0;
			margin: 0 -15px -5px -15px;

			@include gridle_state(tablet-small) {
				flex-direction: column;
			}
		}

		&__item {
			padding: 0 15px;

			margin-bottom: 5px;

			.c-label {
				font-size: 1.8rem;

				color: $color_white;

				opacity: 1;

				@include transition(opacity, .3s);

				@include gridle_state(mobile-middle) {
					font-size: 1.6rem;
				}

				&:hover {
					opacity: 0.5;
				}
			}
		}
	}
}
