.b-box {
	&--search {
		display: flex;
		align-items: flex-end;

		border-right: 1px solid $color_white;

		padding-left: 30px;
		padding-right: 20px;

		@include gridle_state(laptop-small) {
			padding-left: 15px;
			padding-right: 15px;
		}

		@include gridle_state(tablet) {
			border: 0;
			margin-left: 50px;
		}

		@include gridle_state(mobile-small) {
			margin-left: 45px;
		}

		@include gridle_state(mobile-xs-small) {
			display: none;
		}

		.c-btn {
			margin-left: 15px;

			@include gridle_state(laptop-small) {
				margin-left: 10px;
			}
		}
	}
}