.f-control {
    &--text {
        height: 30px;

		font-size: 1.2rem;

        border: 1px solid $color_dark;

        padding: 5px 40px 5px 10px;

		&:focus {
			border-color: $color_black;
			box-shadow: 0 0 1px $color_black;
		}

        &::-webkit-input-placeholder {
            color: $color_gray;
        }

		&::-moz-placeholder {
			color: $color_gray;
		}

		&:-ms-input-placeholder {
			color: $color_gray;
		}

		&:-moz-placeholder {
			color: $color_gray;
		}
    }
}