.b-box {
	&--opinion {
		display: flex;
		justify-content: space-between;
		align-items: stretch;

		padding-top: 40px;

		margin: 0 -20px;

		@include gridle_state(laptop-middle) {
			padding-top: 30px;
		}

		@include gridle_state(tablet) {
			margin: 0 -10px;
		}

		@include gridle_state(tablet-small) {
			flex-direction: column;

			margin: 0;
		}

		&--horizontal {
			flex-direction: column;
		}

		&__row {
			display: flex;
			justify-content: space-between;
			align-items: stretch;

			width: 100%;

			padding-bottom: 40px;

			@include gridle_state(laptop-middle) {
				padding-bottom: 30px;
			}

			@include gridle_state(tablet-small) {
				flex-direction: column;
			}

			&:last-child {
				padding-bottom: 0;
			}

			&--alt {
				flex-direction: column;
			}
		}

		&__wide-col {
			display: flex;

			width: 100%;

			margin-left: 20px;
			margin-right: 20px;

			padding-bottom: 40px;

			@include gridle_state(tablet) {
				margin-left: 10px;
				margin-right: 10px;
			}

			@include gridle_state(tablet-small) {
				flex-direction: column;

				margin-left: 0;
				margin-right: 0;

				padding-bottom: 20px;
			}

			&:last-child {
				padding-bottom: 0;
			}

			&--alt {
				margin-left: 0;
				margin-right: 0;
			}

			&--horizontal {
				flex-direction: column;
			}
		}

		&__col {
			width: 50%;

			margin-left: 20px;
			margin-right: 20px;

			@include gridle_state(tablet) {
				margin-left: 10px;
				margin-right: 10px;
			}

			@include gridle_state(tablet-small) {
				margin-left: 0;
				margin-right: 0;
			}

			@include gridle_state(tablet-small) {
				width: 100%;
			}

			&:first-child {
				position: relative;
				z-index: 1;
			}

			&--alt {
				padding-left: 50px;

				@include gridle_state(laptop-small) {
					padding-left: 0;
				}
			}
		}

		&__pic {
			position: relative;

			margin-bottom: 30px;

			@include gridle_state(tablet) {
				margin-bottom: 15px;
			}

			&--alt {
				margin-top: 60px;

				@include gridle_state(tablet) {
					margin-top: 30px;
				}
			}

			&__btn {
				position: absolute;

				right: 0;
				bottom: 0;
			}
		}

		&__img {
			width: 100%;
			height: 390px;

			background-repeat: no-repeat;
			background-size: cover;

			@include gridle_state(laptop-middle) {
				height: 350px;
			}

			@include gridle_state(tablet) {
				height: 300px;
			}
		}

		&__main-content {
			display: flex;
			justify-content: center;

			padding-top: 10px;

			@include gridle_state(tablet) {
				padding: 20px;
			}

			@include gridle_state(tablet-small) {
				padding: 15px 0;
			}

			.t-content-text {
				max-width: 400px;

				@include gridle_state(tablet-small) {
					max-width: 100%;
				}
			}
		}

		&__content {
			padding-top: 25px;
			padding-right: 40px;
			padding-bottom: 25px;

			margin-bottom: 10px;

			@include gridle_state(laptop-middle) {
				padding-top: 15px;
				padding-bottom: 10px;
			}

			@include gridle_state(tablet) {
				margin-bottom: 0;

				padding-right: 0;
			}

			&--alt {
				padding-top: 25px;
				padding-right: 70px;

				@include gridle_state(laptop-middle) {
					padding-top: 0;
					padding-right: 0;
				}
			}
		}

		&__blue-badge {
			display: flex;
			align-items: center;

			position: relative;

			color: $color_white;

			min-height: 190px;
			width: 680px;

			background-color: $color_blue;

			margin-top: 30px;
			margin-bottom: 40px;
			margin-left: -139px;

			padding: 30px 20px 30px 140px;

			z-index: -1;

			@include gridle_state(laptop-middle) {
				margin-top: 15px;
				margin-bottom: 30px;
			}

			@include gridle_state(laptop-small) {
				width: 100%;

				margin-left: 0;
				padding: 30px;
			}

			@include gridle_state(tablet) {
				min-height: 0;

				margin-top: 10px;
				margin-bottom: 20px;

				padding: 30px 20px;
			}

			&--alt {
				padding-left: 100px;

				margin-top: 0;
				margin-bottom: 0;
				margin-left: -96px;

				@include gridle_state(laptop-small) {
					margin-top: 0;
					margin-bottom: 0;
					margin-left: 0;
					padding: 30px;
				}

				@include gridle_state(tablet) {
					margin-top: 25px;
				}
			}
		}

		&__blockquote {
			display: flex;
			align-items: center;

			width: 100%;
			max-width: 350px;

			margin: 0;

			padding: 40px 0;

			@include gridle_state(tablet) {
				padding-bottom: 0;
			}

			&--inner {
				padding-top: 60px;

				@include gridle_state(tablet) {
					padding-top: 25px;
				}

				.b-box--opinion__blockquote__desc {

					&:before {
						content: url('../img/icons/ico-quote.png');
					}
				}
			}

			&--alt {
				padding-top: 70px;
				padding-bottom: 0;

				@include gridle_state(tablet-small) {
					padding: 60px 0 30px 0;
				}
			}

			&__desc {
				display: block;

				position: relative;

				font-family: $font_noticia;
				font-size: 2.2rem;
				font-style: italic;

				margin: 0;

				@include gridle_state(laptop-middle) {
					font-size: 2rem;
				}

				@include gridle_state(tablet) {
					font-size: 1.8rem;
				}

				&:before {
					display: block;

					position: absolute;

					top: -30px;
					left: 0;

					content: url('../img/icons/ico-quote-blue.png');
				}
			}
		}

		&__btn {
			padding-bottom: 30px;

			@include gridle_state(tablet-small) {
				padding-top: 15px;
			}
		}
	}
}