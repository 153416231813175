.b-box {
	&--scroll {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;

		margin: 0 -10px -20px -10px;
		padding-top: 30px;

		@include gridle_state(tablet) {
			padding-top: 10px;
		}

		.c-btn {
			margin: 0 10px 20px 10px;
		}
	}
}