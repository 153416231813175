.b-box {
	&--article-teaser {
		display: flex;
		flex-direction: column;
		justify-content: center;

		&__title {
			font-size: 3rem;
			font-weight: 300;

			margin: 0 0 20px 0;

			@include gridle_state(tablet) {
				margin-bottom: 15px;
			}

			@include gridle_state(mobile-middle) {
				font-size: 2.4rem;
			}
		}

		&__desc {
			padding-bottom: 30px;

			@include gridle_state(tablet) {
				padding-bottom: 20px;
			}
		}

		&__btn {

		}
	}
}