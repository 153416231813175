.b-box {
	&--footer {
		display: flex;
		flex-direction: column;
		justify-content: center;

		&__top {
			padding-bottom: 15px;

			@include gridle_state(tablet-small) {
				padding-bottom: 30px;
			}
		}

		&__bottom {
			width: 100%;

			margin-top: -20px;
			margin-bottom: -20px;

			&__row {
				display: flex;
				justify-content: space-between;

				padding: 20px 0;

				@include gridle_state(tablet-small) {
					padding: 10px 0;
				}

				@include gridle_state(mobile-small) {
					flex-direction: column;
					align-items: center;
				}

				&:first-child {
					border-bottom: 1px solid $color_white;
				}
			}

			&__col {
				display: flex;
				align-items: center;

				@include gridle_state(mobile-small) {
					padding-top: 5px;
					padding-bottom: 5px;
				}

				&--alt {
					align-items: flex-end;

					@include gridle_state(mobile-small) {
						order: 2;
					}
				}
			}
		}
	}
}