.c-list {
	&--features {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: wrap;

		list-style: none;

		margin: 0 -30px -40px -30px;
		padding: 40px 0 0 0;

		@include gridle_state(laptop-middle) {
			padding-top: 20px;
		}

		@include gridle_state(tablet) {
			padding-top: 10px;
		}

		@include gridle_state(tablet-small) {
			padding-top: 0;
			margin-bottom: -30px;
		}

		&__item {
			padding-left: 30px;
			padding-right: 30px;

			margin-bottom: 40px;

			width: 100%;

			@include gridle_state(tablet-small) {
				margin-bottom: 30px;
			}
		}
	}
}

.c-list {
	&--features {
		$that: &;

		&--i2 {
			#{$that} {
				&__item {
					width: (100 / 2) * 1%;

					@include gridle_state(tablet) {
						width: (100 / 2) * 1%;
					}

					@include gridle_state(tablet-small) {
						width: (100 / 1) * 1%;
					}

					@include gridle_state(mobile-small) {
						width: (100 / 1) * 1%;
					}
				}
			}
		}

		&--i3 {
			#{$that} {
				&__item {
					width: (100 / 3) * 1%;

					@include gridle_state(tablet) {
						width: (100 / 3) * 1%;
					}

					@include gridle_state(tablet-small) {
						width: (100 / 2) * 1%;
					}

					@include gridle_state(mobile-small) {
						width: (100 / 1) * 1%;
					}
				}
			}
		}

		&--i4 {
			#{$that} {
				&__item {
					width: (100 / 4) * 1%;

					@include gridle_state(tablet) {
						width: (100 / 4) * 1%;
					}

					@include gridle_state(tablet-small) {
						width: (100 / 2) * 1%;
					}

					@include gridle_state(mobile-xs-small) {
						width: (100 / 1) * 1%;
					}
				}
			}
		}
	}
}
