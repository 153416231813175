.f-label {
	&--default {
		display: block;
		font-weight: 300;

		margin-bottom: 10px;

		span {
			color: $color_red;
		}

		&.f-label--checkbox,
		&.f-label--radio {
			font-size: 1.4rem;
			display: inline-block;
			cursor: pointer;
		}
	}
}
