.b-box {
	&--gallery {
		display: block;

		width: 100%;
		min-height: 400px;

		overflow: hidden;

		@include gridle_state(laptop-middle) {
			width: 100%;
			min-height: 340px;
		}

		@include gridle_state(tablet-small) {
			width: 100%;
		}

		&:hover {

			.b-box--gallery__pic {
				transform: scale(1.05);
			}
		}

		&__pic {
			width: 100%;
			height: 290px;

			background-size: cover;
			background-repeat: no-repeat;

			transform: scale(1);

			@include transition(all, .5s);

			@include gridle_state(laptop-middle) {
				height: 250px;
			}
		}

		&__desc {
			display: block;

			position: relative;

			font-family: $font_noticia;
			font-size: 1.8rem;
			font-style: italic;
			color: $color_white;

			min-height: 110px;

			background-color: $color_blue;

			margin: 0;
			padding: 30px;

			z-index: 1;

			@include gridle_state(laptop-middle) {
				min-height: 90px;

				font-size: 1.6rem;

				padding: 20px 30px;
			}
		}
	}
}