.b-box {
	&--reviews {
		display: flex;

		padding-top: 70px;

		@include gridle_state(laptop-middle-xs) {
			padding-top: 40px;
		}

		@include gridle_state(tablet) {
			padding-top: 35px;
		}

		@include gridle_state(mobile-middle) {
			flex-direction: column;
		}

		&__col {
			flex-shrink: 0;
			position: relative;

			width: 650px;

			@include gridle_state(laptop-middle-xs) {
				width: 50%;
			}

			@include gridle_state(mobile-middle) {
				width: 100%;
			}

			&--left {
				margin-right: -45px;

				z-index: 1;

				@include gridle_state(laptop-middle-xs) {
					margin-right: 0;
				}
			}

			&--right {
				margin-left: -45px;

				@include gridle_state(laptop-middle-xs) {
					margin-left: 0;
				}
			}
		}

		&__btn {
			display: flex;
			justify-content: flex-end;

			padding-top: 20px;
		}
	}
}