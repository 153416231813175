.b-box {
	&--hero-small {
		display: flex;
		align-items: center;

		position: relative;

		min-height: 400px;

		z-index: 1;

		@include gridle_state(tablet) {
			min-height: 350px;
		}

		@include gridle_state(mobile-middle) {
			height: auto;

			min-height: 300px;
		}

		&__content {
			display: flex;
			align-items: center;
			justify-content: flex-start;

			min-height: 240px;
			min-width: 480px;

			background: rgba(237, 28, 36, 0.7);

			padding: 40px 55px;

			@include gridle_state(laptop-small) {
				min-height: 0;

				padding: 30px 40px;
			}

			@include gridle_state(mobile-middle) {
				min-width: 100%;

				padding: 30px;
			}
		}

		&__title {
			font-size: 4.2rem;
			font-weight: 300;

			color: $color_white;

			margin: 0;

			@include gridle_state(laptop-middle) {
				font-size: 3.8rem;
			}

			@include gridle_state(laptop-small) {
				font-size: 3.6rem;
			}

			@include gridle_state(tablet) {
				font-size: 3.2rem;
			}

			@include gridle_state(mobile-middle) {
				font-size: 3rem;
			}
		}
	}
}