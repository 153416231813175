.t-head {
	&--footer {
		font-family: $font_noticia;
		font-size: 1.8rem;
		font-style: italic;

		color: $color_white;

		margin-top: 0;
		margin-bottom: 15px;
	}
}
