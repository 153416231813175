.c-list {
	&--news {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: space-between;

		height: 100%;

		list-style: none;

		padding: 20px 0 0 40px;
		margin: 0;

		@include gridle_state(tablet) {
			padding-top: 30px;
			padding-left: 0;
		}

		&__item {
			width: 100%;

			padding: 0;
			margin-bottom: 20px;
		}
	}
}
