.t-content-text {
	&--primary {

		h2 {
			@extend %helper-clear-margins;

		}

		h3 {
			@extend %helper-clear-margins;

		}

		h4 {
			@extend %helper-clear-margins;

			font-size: 2rem;
			font-weight: 300;

			margin-bottom: 10px;
		}

		h5 {
			font-size: 1.6rem;

			margin: 25px 0;

			@include gridle_state(laptop-small) {
				margin: 15px 0;
			}

			@include gridle_state(mobile-middle) {
				font-size: 1.4rem;

				margin: 5px 0;
			}
		}

		a {

		}

		p {
			@extend %helper-clear-margins;

			font-size: 2rem;

			margin-top: 0;
			margin-bottom: 20px;

			@include gridle_state(laptop-middle) {
				font-size: 1.8rem;
			}

			@include gridle_state(tablet) {
				font-size: 1.6rem;

				margin-bottom: 10px;
			}
		}

		span {
			font-size: 1.4rem;
		}

		ul {
			list-style: none;

			padding: 20px 0 0 15px;
			margin: 0;

			@include gridle_state(mobile-middle) {
				justify-content: center;
			}

			li {
				position: relative;

				font-family: $font_noticia;
				font-size: 1.8rem;
				font-style: italic;
				text-indent: -15px;

				margin-bottom: 25px;

				@include gridle_state(tablet) {
					font-size: 1.6rem;
				}

				&:before {
					display: inline-block;

					position: relative;

					width: 9px;
					height: 9px;

					top: -2px;

					background-color: $color_red;
					border-radius: 50%;

					content: '';

					margin-right: 5px;
				}
			}
		}

	}
}
