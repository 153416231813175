.t-content-text {
	&--large {

		h2 {
			@extend %helper-clear-margins;

		}

		h3 {
			@extend %helper-clear-margins;

			font-size: 3rem;
			font-weight: 300;

			margin-bottom: 25px;

			@include gridle_state(laptop-middle) {
				font-size: 2.6rem;

				margin-bottom: 20px;
			}

			@include gridle_state(laptop-small) {
				font-size: 2.4rem;
			}

			@include gridle_state(tablet) {
				font-size: 2.2rem;
				margin-bottom: 15px;
			}

			@include gridle_state(mobile-middle) {
				font-size: 2rem;
			}
		}

		h4 {
			@extend %helper-clear-margins;

			font-size: 2rem;
			font-weight: 300;

			margin-bottom: 10px;
		}

		h5 {
			font-size: 1.6rem;

			margin: 25px 0;

			@include gridle_state(laptop-small) {
				margin: 15px 0;
			}

			@include gridle_state(mobile-middle) {
				font-size: 1.4rem;

				margin: 5px 0;
			}
		}

		a {
			color: $color_red;
		}

		p {
			@extend %helper-clear-margins;

			font-size: 2.2rem;

			margin-top: 0;
			margin-bottom: 15px;

			@include gridle_state(mobile-middle) {
				font-size: 1.8rem;
			}
		}

		ul {
			list-style: none;
			font-size: 2.2rem;

			padding-left: 23px;
			margin: 0 0 10px 0;

			@include gridle_state(laptop-middle) {
				font-size: 2rem;
			}

			@include gridle_state(tablet) {
				font-size: 1.8rem;
			}

			li {
				padding-bottom: 5px;

				text-indent: -23px;


				&:before {
					display: inline-block;
					position: relative;

					bottom: 3px;

					width: 7px;
					height: 7px;

					background-color: $color_yellow;
					border-radius: 50%;

					margin-right: 10px;

					content: '';
				}

				a {
					color: $color_dark;

					font-weight: 400;
				}
			}
		}

	}
}
