.c-list {
	&--default {
		display: flex;
		align-items: stretch;
		justify-content: flex-start;
		flex-wrap: wrap;

		height: 100%;

		margin: 0 -5px;
		padding: 10px 0 10px 0;

		&__item {
			display: flex;
			align-items: stretch;

			padding-left: 5px;
			padding-right: 5px;

			margin-bottom: 10px;

			width: 100%;
		}
	}
}

.c-list {
	&--default {
		$that: &;

		&--i3 {
			#{$that} {
				&__item {
					width: (100 / 3) * 1%;

					@include gridle_state(tablet) {
						width: (100 / 3) * 1%;
					}

					@include gridle_state(tablet-small) {
						width: (100 / 2) * 1%;
					}

					@include gridle_state(mobile-small) {
						width: (100 / 1) * 1%;
					}
				}
			}
		}

		&--i4 {
			#{$that} {
				&__item {
					width: (100 / 4) * 1%;

					@include gridle_state(tablet) {
						width: (100 / 4) * 1%;
					}

					@include gridle_state(tablet-small) {
						width: (100 / 2) * 1%;
					}

					@include gridle_state(mobile-xs-small) {
						width: (100 / 1) * 1%;
					}
				}
			}
		}
	}
}
