.c-btn {
	&--search-ico {
		width: 20px;
		height: 20px;

		text-indent: -777rem;

		border: 0;
		background: none;

		cursor: pointer;

		&--alt {
			display: flex;
			align-items: center;
			justify-content: center;

			width: 40px;
			height: 40px;

			padding: 0;
		}
	}
}