.c-list {
	&--pages {
		display: flex;
		flex-direction: column;

		list-style: none;

		margin: 0 0 -60px 0;
		padding: 10px 0 0 0;

		@include gridle_state(laptop-small) {
			margin-bottom: -40px;
		}

		@include gridle_state(tablet) {
			margin-bottom: -30px;
		}

		&__item {
			display: none;
			padding: 0;
			margin-bottom: 60px;

			@include gridle_state(laptop-small) {
				margin-bottom: 40px;
			}

			@include gridle_state(tablet) {
				margin-bottom: 30px;
			}

			&.is-visible {
				display: block;
			}
		}
	}
}