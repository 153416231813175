/* Inner
   ========================================================================== */

.l-inner {
	@extend %helper-inner;

	&--alt {
		max-width: 100%;

		padding: 0;
	}
}

.l-page {
	overflow: hidden;
	max-width: 100%;
	margin: auto;
}

.l-sec {
	position: relative;
}
