.t-head {
	&--default {
		@extend %helper-clear-margins;

		font-family: $font_noticia;
		font-size: 2.6rem;
		font-weight: 400;
		font-style: italic;

		margin-bottom: 20px;

		@include gridle_state(laptop-middle) {
			font-size: 2.4rem;

			margin-bottom: 10px;
		}

		@include gridle_state(mobile-middle) {
			font-size: 2.2rem;
		}
	}
}
