.l-sec {
	&--hero {

		&--img {
			position: relative;

			min-height: 450px;

			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;

			@include gridle_state(laptop-small) {
				min-height: 400px;
			}

			@include gridle_state(tablet) {
				min-height: 350px;
			}

			@include gridle_state(mobile-middle) {
				min-height: 300px;
			}
		}

		&--lang {
			position: relative;

			height: 220px;

			background-repeat: no-repeat;
			background-position: center center;

			@include gridle_state(tablet) {
				height: 190px;
			}

			@include gridle_state(mobile-middle) {
				height: 160px;
			}

			.l-inner {
				height: 100%;
			}
		}
	}
}
