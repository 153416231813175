.c-table {
	&--default {
		width: 100%;

		text-align: center;
		border-collapse: collapse;

		margin-top: 50px;
		margin-bottom: 50px;

		@include gridle_state(tablet) {
			margin-top: 30px;
			margin-bottom: 30px;
		}

		@include gridle_state(tablet-small) {
			display: block;
		}

		.c-table__row-head {
			vertical-align: middle;

			background-color: $color_gray_11;

			@include gridle_state(tablet-small) {
				display: block;

				position: absolute;
				top: -9999px;
				left: -9999px;
			}
		}

		.c-table__body {
			@include gridle_state(tablet-small) {
				display: block;
			}
		}

		.c-table__row {
			vertical-align: middle;

			@include gridle_state(tablet-small) {
				display: block;

				border: 0
			}

			&:nth-child(odd) {
				background-color: $color_gray_12;

				@include gridle_state(tablet-small) {
					background-color: lighten($color_gray_12, 3.5%);
				}
			}

			&:nth-child(even) {
				background-color: $color_gray_13;

				@include gridle_state(tablet-small) {

				}
			}
		}

		.c-table__head {
			font-size: 1.6rem;
			font-weight: 500;
			line-height: 1.4;

			max-width: 200px;
			height: 70px;

			border: 1px solid $color_white;
			background-color: $color_gray_11;

			padding: 15px;

			@include gridle_state(tablet-small) {
				display: block;

				max-width: 100%;
			}
		}

		.c-table__cell {
			position: relative;

			font-size: 1.6rem;
			line-height: 1.4;
			font-weight: 300;

			max-width: 200px;
			height: 70px;

			border: 1px solid $color_white;

			padding: 15px;

			@include gridle_state(tablet-small) {
				display: flex;
				justify-content: space-around;
				align-items: center;

				max-width: 100%;

				padding-top: 10px;
				padding-bottom: 10px;
				padding-left: 50%;
			}

			&:before {
				@include gridle_state(tablet-small) {
					position: absolute;

					font-weight: 500;
					text-align: left;

					top: 50%;
					left: 0;

					width: 45%;

					padding-right: 10px;
					padding-left: 20px;

					white-space: nowrap;

					transform: translateY(-50%);
				}
			}

			&:nth-of-type(1):before,
			&:nth-of-type(2):before,
			&:nth-of-type(3):before,
			&:nth-of-type(4):before,
			&:nth-of-type(5):before,
			&:nth-of-type(6):before {
				@include gridle_state(tablet-small) {
					content: attr(data-label);
				}
			}
		}

		&--alt {

			.c-table__head {

				@include gridle_state(tablet-small) {
					display: flex;
					justify-content: center;
					align-items: center;

					max-width: 100%;
				}
			}

			.c-table__cell {

				@include gridle_state(tablet-small) {
					display: flex;
					justify-content: center;
					align-items: center;

					padding-left: 0;
				}

				&:before {
					@include gridle_state(tablet-small) {
						display: none;
					}
				}
			}
		}
	}
}