.f-control {
	&--default {
		color: $color_white;

		width: 100%;
		min-height: 45px;

		border: 1px solid $color_white;
		background-color: transparent;

		box-shadow: none;
		-webkit-appearance: none;
		resize: none;

		padding: 10px;

		&:focus {
			border: 1px solid $color_blue;
			outline: none;
		}

		*::-ms-clear {
			display: none;
		}

		//&::-webkit-input-placeholder {
		//	color: $color_gray_3;
		//}
		//&::-moz-placeholder {
		//	color: $color_gray_3;
		//}
		//&:-ms-input-placeholder {
		//	color: $color_gray_3;
		//}
		//&:-moz-placeholder {
		//	color: $color_gray_3;
		//}
	}
}
