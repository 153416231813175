.c-btn {
	&--default {
		display: inline-block;

		position: relative;

		height: 50px;

		border: 0;
		background-color: $color_red;
		outline: none;

		padding: 0 25px;

		cursor: pointer;

		z-index: 2;

		@include transition(all, .3s);

		@include gridle_state(mobile-small) {
			padding: 0 10px;
		}

		&:hover {
			background-color: darken($color_red, 17%);
		}

		&--alt {
			min-width: 205px;
		}

		&--large {
			width: 100%;

			.c-btn--default__label {
				padding-right: 40px;
			}
		}

		&__wrap {
			display: flex;
			align-items: center;
			justify-content: center;

			width: 100%;
			height: 100%;
		}

		&__label {
			font-size: 1.6rem;

			color: $color_white;

			padding-right: 15px;

			@include gridle_state(mobile-middle) {
				font-size: 1.4rem;
			}
		}

		&__ico {

		}
	}
}