.b-box {
	&--icon {
		display: flex;
		flex-direction: column;

		max-width: 250px;

		&__pic {
			display: flex;
			justify-content: center;
			align-items: flex-end;
			height: 100px;
		}

		&__desc {
			flex: 1 0 0;

			padding-top: 30px;
		}
	}
}