.b-box {
	&--review {
		width: 100%;
		max-width: 650px;

		@include gridle_state(mobile-middle) {
			max-width: 100%;
		}

		&--alt {
			//padding-left: 150px;
		}

		&__pic {
			width: 100%;
			height: 430px;

			background-repeat: no-repeat;

			@include gridle_state(laptop) {
				height: 390px;
			}

			@include gridle_state(laptop-middle) {
				height: 350px;
			}

			@include gridle_state(tablet) {
				height: 320px;
			}

			@include gridle_state(mobile-middle) {
				height: 300px;
			}
		}

		&__main {
			display: flex;
			justify-content: center;

			padding-top: 50px;

			@include gridle_state(tablet) {
				padding: 20px;
			}

			@include gridle_state(mobile-middle) {
				padding: 20px 0 10px 0;
			}
		}

		&__desc {
			width: 100%;

			margin: 0;

			&--alt {
				max-width: 400px;

				@include gridle_state(mobile-middle) {
					max-width: 100%;
				}
			}
		}

		&__head {
			padding: 20px 30px 40px 150px;

			@include gridle_state(laptop-middle-xs) {
				padding-left: 60px;
			}

			@include gridle_state(laptop-small) {
				padding-left: 40px;
			}

			@include gridle_state(tablet) {
				padding: 20px;
			}

			@include gridle_state(mobile-middle) {
				padding: 10px 0 20px 0;

				text-align: center;
			}
		}

		&__title {
			font-family: $font_noticia;
			font-size: 2.2rem;
			font-style: italic;
			font-weight: 400;

			color: $color_red;

			margin-top: 0;
			margin-bottom: 20px;
		}

		&__quote {
			display: flex;
			align-items: center;

			width: 100%;
			min-height: 360px;

			background-color: $color_blue;

			padding: 70px 95px 70px 150px;

			@include gridle_state(laptop) {
				min-height: 300px;
			}

			@include gridle_state(laptop-middle) {
				min-height: 280px;

				padding: 60px 60px 60px 150px;
			}

			@include gridle_state(laptop-middle-xs) {
				padding-left: 60px;
			}

			@include gridle_state(laptop-small) {
				min-height: 260px;

				padding: 55px 40px 40px 40px;
			}

			@include gridle_state(tablet) {
				padding: 55px 30px 30px 30px;
			}

			@include gridle_state(mobile-middle) {
				min-height: 0;
			}

			&__desc {
				display: block;

				position: relative;

				font-family: $font_noticia;
				font-size: 2.2rem;
				font-style: italic;

				color: $color_white;

				margin: 0;

				@include gridle_state(mobile-middle) {
					font-size: 1.8rem;
				}

				&:before {
					display: block;

					position: absolute;

					top: -30px;
					left: 0;

					content: url('../img/icons/ico-quote.png');
				}
			}
		}
	}
}