.c-btn {
	&--arrow {
		display: inline-block;

		width: 50px;
		height: 50px;

		border: 0;
		background-color: $color_red;
		outline: none;

		margin: 0 8px;

		cursor: pointer;

		@include transition(all, .3s);

		@include gridle_state(mobile-middle) {
			margin: 0;
		}

		&:hover {
			background-color: darken($color_red, 17%);
		}

		&__wrap {
			display: flex;
			align-items: center;
			justify-content: center;

			width: 100%;
			height: 100%;
		}

		&__ico {

		}
	}
}