.c-list {
	&--social {
		display: flex;
		flex-wrap: wrap;
		position: relative;

		list-style: none;

		padding: 0 35px 0 0;
		margin: 0 -4px -5px -4px;

		@include gridle_state(tablet-small) {
			padding-right: 20px;
		}

		&--alt {
			padding: 0;
		}

		&__item {
			padding: 0 4px;

			margin-bottom: 5px;

			& > .c-label {
				display: block;

				opacity: 1;

				@include transition(all, .3s);

				&:hover {
					opacity: 0.5;
				}
			}
		}
	}
}
