.b-box {
	&--new {
		padding-top: 30px;

		@include gridle_state(laptop-middle) {
			padding-top: 15px;
		}

		&__row {
			display: flex;
			justify-content: space-between;

			margin: 0 -20px;
			padding-bottom: 30px;

			@include gridle_state(laptop-middle) {
				padding-bottom: 20px;
			}

			@include gridle_state(tablet-small) {
				flex-direction: column;

				margin: 0;
			}

			&:last-child {
				padding-bottom: 0;
			}
		}

		&__content {
			width: 50%;

			margin: 0 20px;

			@include gridle_state(tablet-small) {
				width: 100%;

				padding-bottom: 20px;
				margin: 0;
			}
		}

		&__pic {
			width: 50%;

			margin: 0 20px;

			@include gridle_state(tablet-small) {
				width: 100%;

				margin: 0;
			}

			.c-image {
				width: 100%;
			}
		}
	}
}