.b-box {
	&--address-small {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		&__title {
			flex: 1 0 0;

			font-size: 1.8rem;
			font-weight: 300;
			color: $color_white;

			margin-top: 0;
			margin-bottom: 15px;
		}

		&__phone {
			font-size: 2rem;
			font-weight: 700;
		}

		&__content {
			margin-bottom: 30px;

			@include gridle_state(laptop-middle) {
				margin-bottom: 20px;
			}

			&__list {
				font-size: 1.6rem;
				font-weight: 400;

				list-style: none;

				padding: 0;

				@include gridle_state(mobile-middle) {
					font-size: 1.4rem;
				}

				li {
					padding: 0;

					a {
						color: $color_white;
					}
				}
			}
		}

		&__social {
			padding-top: 30px;
		}
	}
}