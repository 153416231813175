.c-list {
	&--icons {
		display: flex;
		flex-wrap: wrap;
		position: relative;

		list-style: none;

		padding: 15px 0 0 0;
		margin: 0 -70px -20px -70px;

		&__item {
			padding: 0 70px;

			margin-bottom: 20px;
		}
	}
}
