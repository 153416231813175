.l-sec {
	&--head {
		position: fixed;

		top: 0;
		left: 0;

		width: 100%;
		height: 100px;

		color: $color_gray;
		background-color: $color_blue;

		z-index: 999;

		@include gridle_state(tablet) {
			position: relative;
		}

		@include gridle_state(mobile-small) {
			height: 80px;
		}

		&--white {
			position: relative;

			height: 140px;
			background-color: $color_white;

			@include gridle_state(tablet-small) {
				height: 120px;
			}

			@include gridle_state(mobile-middle) {
				height: 100px;
			}

			@include gridle_state(mobile-small) {
				height: 80px;
			}
		}

		&.is-menu-opened {

			.l-sec--head__box {
				transform: translate(0, 0);
			}
		}

		&__row {
			display: flex;
			justify-content: space-between;

			height: 100%;

			@include gridle_state(tablet) {
				align-items: center;

				height: 100px;

				padding-top: 0;
			}

			@include gridle_state(mobile-small) {
				height: 80px;
			}

			&__inner {
				display: flex;
				align-items: flex-start;
				justify-content: flex-end;

				padding-top: 15px;

				@include gridle_state(tablet) {
					display: block;

					height: 100%;
					width: 50%;

					padding-top: 0;
				}

				@include gridle_state(mobile-middle) {
					height: auto;
					width: 100%;
				}
			}

			&__nav {
				height: 45px;

				padding-top: 0;

				@include gridle_state(tablet) {
					height: 100%;

					background: $color_gray;
				}

				&__lang {
					display: flex;
					align-items: flex-start;

					height: 45px;

					@include gridle_state(tablet) {
						display: none;
					}
				}
			}

			&--search {

			}
		}

		&__box {
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			flex-direction: column;

			height: 100px;
			width: 100%;

			@include gridle_state(tablet) {
				display: flex;
				flex-direction: row;

				position: absolute;

				top: 100px;
				right: 0;

				width: 100%;
				height: calc(100vh - 100px);

				text-align: center;
				border-left: 1px solid $color_blue;

				background: $color_gray_8;

				padding: 0;

				transform: translate(100%, 0);

				@include transition(all, .3s);
			}

			@include gridle_state(mobile-middle) {
				flex-direction: column;

				height: auto;
			}
		}
	}
}
