.c-list {
	&--country {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;

		list-style: none;

		margin: 0 -20px -75px -20px;
		padding: 0;

		@include gridle_state(tablet) {
			margin-bottom: -50px;
		}

		@include gridle_state(tablet-small) {
			margin-bottom: -40px;
		}

		@include gridle_state(mobile-small) {
			margin-bottom: -30px;
		}

		&__item {
			padding-left: 20px;
			padding-right: 20px;

			margin-bottom: 75px;

			@include gridle_state(tablet) {
				margin-bottom: 50px;
			}

			@include gridle_state(tablet-small) {
				margin-bottom: 40px;
			}

			@include gridle_state(mobile-small) {
				margin-bottom: 30px;
			}
		}
	}
}

.c-list {
	&--country {
		$that: &;

		&--i6 {
			#{$that} {
				&__item {
					width: (100 / 6) * 1%;

					@include gridle_state(laptop-small) {
						width: (100 / 5) * 1%;
					}

					@include gridle_state(tablet) {
						width: (100 / 4) * 1%;
					}

					@include gridle_state(tablet-small) {
						width: (100 / 3) * 1%;
					}

					@include gridle_state(mobile-small) {
						width: (100 / 2) * 1%;
					}
				}
			}
		}
	}
}
