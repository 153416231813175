.t-content-text {
	&--default {

		h2 {
			@extend %helper-clear-margins;

		}

		h3 {
			@extend %helper-clear-margins;

			font-size: 3rem;
			font-weight: 300;

			margin-bottom: 25px;

			@include gridle_state(laptop-middle) {
				font-size: 2.8rem;

				margin-bottom: 15px;
			}

			@include gridle_state(mobile-middle) {
				font-size: 2.4rem;
			}
		}

		h4 {
			@extend %helper-clear-margins;

			font-size: 2.2rem;
			font-family: $font_noticia;
			font-weight: 400;

			margin-bottom: 25px;

			@include gridle_state(tablet) {
				margin-bottom: 15px;
			}

			@include gridle_state(mobile-middle) {
				font-size: 2rem;
			}
		}

		h5 {
			font-size: 1.6rem;

			margin: 25px 0;

			@include gridle_state(laptop-small) {
				margin: 15px 0;
			}

			@include gridle_state(mobile-middle) {
				font-size: 1.4rem;

				margin: 5px 0;
			}
		}

		a {
			color: $color_red;
		}

		p {
			@extend %helper-clear-margins;

			margin-top: 0;
			margin-bottom: 15px;
		}

		span {
			font-size: 1.4rem;
		}

		ul {
			list-style: none;

			padding-left: 15px;
			margin: 10px 0;

			li {
				padding-bottom: 2px;

				&:before {
					display: inline-block;
					position: relative;

					bottom: 3px;

					width: 7px;
					height: 7px;

					background-color: $color_red;
					border-radius: 50%;

					margin-right: 10px;

					content: '';
				}

				a {
					color: $color_dark;

					font-weight: 400;
				}
			}
		}

		table {
			line-height: 1.2;

			border: 0;

			td {
				border: 0;
				vertical-align: top;

				padding: 1px;

				&:first-child {
					white-space: nowrap;

					padding-right: 10px;
				}
			}
		}
	}
}
