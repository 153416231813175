.f-control {
    &--search {
		color: $color_white;
		font-size: 1.4rem;

		padding: 2px 5px;

		width: 145px;

		border: 0;
		background: transparent;
		border-bottom: 1px solid $color_white;

		@include gridle_state(laptop-small) {
			width: 100px;
		}

		@include gridle_state(tablet) {
			width: 330px;
		}

		@include gridle_state(tablet-small) {
			width: 230px;
		}

		@include gridle_state(mobile-middle) {
			width: 150px;
		}

		@include gridle_state(mobile-small) {
			width: 80px;
		}
    }
}