.b-box {
	&--hero-search {
		display: flex;
		align-items: center;

		position: relative;

		width: 100%;
		height: 450px;

		z-index: 1;

		@include gridle_state(laptop-middle) {
			height: 400px;
		}

		@include gridle_state(laptop-small) {
			height: 350px;
		}

		@include gridle_state(tablet) {
			height: 300px;
		}

		&__body {
			display: flex;
			align-items: center;
			justify-content: center;

			width: 100%;
			height: 240px;

			background: rgba(237, 28, 36, 0.6);

			padding: 30px;

			@include gridle_state(laptop-small) {
				height: 200px;
			}

			@include gridle_state(tablet) {
				height: 150px;

				padding: 30px 20px;
			}

			@include gridle_state(mobile-middle) {
				padding: 30px 10px;
			}
		}

		&__title {
			font-size: 4.2rem;
			font-weight: 300;
			color: $color_white;

			margin: 0;

			padding-right: 40px;

			@include gridle_state(laptop-middle) {
				font-size: 3.6rem;

				padding-right: 20px;
			}

			@include gridle_state(tablet) {
				font-size: 2.6rem;

				padding-right: 15px;
			}

			@include gridle_state(mobile-middle) {
				font-size: 2rem;

				padding-right: 10px;
			}
		}

		&__form {
			display: flex;
			align-items: center;
			justify-content: space-between;

			width: 100%;
			max-width: 920px;
		}

		.c-btn {
			margin-left: 20px;

			@include gridle_state(mobile-middle) {
				margin-left: 15px;
			}

			@include gridle_state(mobile-small) {
				margin-left: 10px;
			}
		}
	}
}