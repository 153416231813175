.b-box {
	&--mobile-lang-search {
		display: none;

		@include gridle_state(tablet) {
			display: flex;

			margin-left: 170px;
		}

		@include gridle_state(mobile-middle) {
			margin-left: 110px;
		}

		@include gridle_state(mobile-small) {
			margin-left: 100px;
		}

		@include gridle_state(mobile-xs-small) {
			height: 20px;

			margin-left: 100px;
		}
	}
}