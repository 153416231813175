.f-field {
	&--hero-search {
		width: 100%;
		max-width: 860px;

		&.is-error {
			input[type="text"],
			input[type="password"],
			input[type="number"],
			input[type="email"],
			textarea {
				border-color: $color_red;
			}

			.f-label {
				&--default {
					color: $color_red;
				}
			}
		}
	}
}
