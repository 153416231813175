.c-logo {
    &--group {
		display: inline-block;

		svg {
			fill: $color_white;

			width: 233px;
			height: 91px;

			@include gridle_state(tablet) {
				width: 180px;
				height: 60px;
			}
		}
	}
}