.b-box {
	&--primary {
		display: flex;
		justify-content: center;
		flex-direction: column;
		padding: 0 20px;

		max-width: 100%;
		height: 100%;

		&__pic {
			display: flex;
			justify-content: center;

			padding-top: 20px;
			padding-bottom: 20px;

			@include gridle_state(laptop-middle) {
				padding-top: 10px;
				padding-bottom: 10px;
			}

			.c-image {

			}
		}

		&__top-title {
			@extend %helper-clear-margins;

			font-family: $font_noticia;
			font-size: 2.2rem;
			font-weight: 400;
			font-style: italic;

			margin-top: 0;
			margin-bottom: 20px;

			@include gridle_state(laptop-middle) {
				font-size: 2rem;
				margin-bottom: 10px;
			}

			@include gridle_state(tablet) {
				font-size: 1.8rem;

				margin-bottom: 5px;
			}
		}

		&__title {
			font-size: 4rem;
			font-weight: 300;

			margin-top: 10px;
			margin-bottom: 15px;

			@include gridle_state(laptop-middle) {
				font-size: 3.6rem;

				margin-bottom: 10px;
			}

			@include gridle_state(mobile-middle) {
				font-size: 3rem;
			}
		}

		&__desc {
			padding-top: 15px;
			padding-bottom: 15px;

			@include gridle_state(laptop-middle) {
				padding-top: 10px;
				padding-bottom: 10px;
			}

			@include gridle_state(mobile-middle) {
				padding-top: 5px;
				padding-bottom: 5px;
			}
		}

		&__icons {
			padding-top: 10px;
		}
	}
}