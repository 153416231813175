.o-ico {
	&--arrow-2 {
		display: block;

		width: 6px;
		height: 12px;

		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;

		&--up {
			height: 6px;
			width: 12px;
			background-image: url('../img/icons/ico-arrow-2-white-up.png');
		}

		&--right {
			background-image: url('../img/icons/ico-arrow-2-white-right.png');
		}

		&--down {
			height: 6px;
			width: 12px;
			background-image: url('../img/icons/ico-arrow-2-white-down.png');
		}

		&--left {
			background-image: url('../img/icons/ico-arrow-2-white-left.png');
		}
	}
}

