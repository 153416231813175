.b-box {
	&--feature {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;

		&__pic {
			flex: 1 0 0;
			margin-bottom: 10px;
		}

		&__title {
			font-size: 3rem;
			font-weight: 300;

			margin-top: 0;
			margin-bottom: 20px;

			@include gridle_state(laptop-middle) {
				font-size: 2.6rem;

				margin-bottom: 10px;
			}

			@include gridle_state(laptop-small) {
				font-size: 2.4rem;
			}

			@include gridle_state(tablet) {
				font-size: 2.2rem;
			}

			@include gridle_state(mobile-middle) {
				font-size: 2rem;
			}
		}

		&__content {

		}
	}
}