.t-head {
	&--hero {
		@extend %helper-clear-margins;

		font-size: 7rem;
		font-weight: 100;

		color: $color_white;

		margin-bottom: 40px;

		@include gridle_state(laptop-middle) {
			font-size: 6.5rem;
		}

		@include gridle_state(laptop-small) {
			font-size: 6rem;
		}

		@include gridle_state(tablet) {
			font-size: 5rem;
		}

		@include gridle_state(mobile-middle) {
			font-size: 4.5rem;
		}
	}
}
