.b-box {
	&--button {
		display: none;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;

		margin: 0 -10px;
		padding-top: 60px;

		@include gridle_state(tablet) {
			padding-top: 40px;
		}

		@include gridle_state(mobile-middle) {
			padding-top: 30px;
		}

		&.is-visible {
			display: flex;
		}

		.c-btn {
			min-width: 360px;

			margin: 0 10px;

			@include gridle_state(mobile-middle) {
				min-width: 200px;
			}
		}
	}
}