.c-list {
	&--articles {
		display: flex;
		align-items: stretch;
		justify-content: flex-start;
		flex-wrap: wrap;

		height: 100%;

		margin: 0 -20px -40px -20px;
		padding: 0;

		@include gridle_state(laptop-middle) {
			margin: 0 -15px -30px -15px;
		}

		@include gridle_state(tablet) {
			margin: 0 -10px -20px -10px;
		}

		&__item {
			display: none;
			align-items: stretch;

			padding-left: 20px;
			padding-right: 20px;

			margin-bottom: 40px;

			width: 100%;

			@include gridle_state(laptop-middle) {
				margin-bottom: 30px;

				padding-left: 15px;
				padding-right: 15px;
			}

			@include gridle_state(tablet) {
				margin-bottom: 20px;

				padding-left: 10px;
				padding-right: 10px;
			}

			&.is-visible {
				display: flex;
			}
		}
	}
}

.c-list {
	&--articles {
		$that: &;

		&--i2 {
			#{$that} {
				&__item {
					width: (100 / 2) * 1%;

					@include gridle_state(tablet-small) {
						width: (100 / 1) * 1%;
					}
				}
			}
		}

		&--i3 {
			#{$that} {
				&__item {
					width: (100 / 3) * 1%;

					@include gridle_state(tablet) {
						width: (100 / 3) * 1%;
					}

					@include gridle_state(tablet-small) {
						width: (100 / 2) * 1%;
					}

					@include gridle_state(mobile-small) {
						width: (100 / 1) * 1%;
					}
				}
			}
		}

		&--i4 {
			#{$that} {
				&__item {
					width: (100 / 4) * 1%;

					@include gridle_state(tablet) {
						width: (100 / 4) * 1%;
					}

					@include gridle_state(tablet-small) {
						width: (100 / 2) * 1%;
					}

					@include gridle_state(mobile-xs-small) {
						width: (100 / 1) * 1%;
					}
				}
			}
		}
	}
}
