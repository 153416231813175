.c-list {
	&--opinions {
		display: block;

		width: 100%;

		padding: 30px 0 0 0;

		list-style: none;

		margin: 0 0 -80px 0;

		@include gridle_state(mobile-middle-xs) {
			//padding-top: 0;
			margin: 0 0 -40px 0;
		}

		@include gridle_state(tablet) {
			padding-top: 0;
			margin-bottom: -10px;
		}

		@include gridle_state(tablet-small) {
			margin-bottom: -30px;
		}

		&__item {
			margin-bottom: 80px;

			@include gridle_state(mobile-middle-xs) {
				margin-bottom: 40px;
			}

			@include gridle_state(tablet) {
				margin-bottom: 10px;
			}

			@include gridle_state(tablet-small) {
				margin-bottom: 30px;
			}
		}
	}
}