.c-list {
	&--products {
		display: flex;
		align-items: stretch;
		justify-content: flex-start;
		flex-wrap: wrap;

		list-style: none;

		margin: 0 -15px -30px -15px;
		padding: 10px 0 0 0;

		&__item {
			display: none;
			align-items: stretch;

			padding-left: 15px;
			padding-right: 15px;

			margin-bottom: 30px;

			width: 100%;

			&.is-visible {
				display: flex;
			}
		}
	}
}

.c-list {
	&--products {
		$that: &;

		&--i2 {
			#{$that} {
				&__item {
					width: (100 / 2) * 1%;

					@include gridle_state(tablet-small) {
						width: (100 / 1) * 1%;
					}
				}
			}
		}

		&--i3 {
			#{$that} {
				&__item {
					width: (100 / 3) * 1%;

					@include gridle_state(tablet) {
						width: (100 / 2) * 1%;
					}

					@include gridle_state(mobile-middle) {
						width: (100 / 1) * 1%;
					}
				}
			}
		}

		&--i4 {
			#{$that} {
				&__item {
					width: (100 / 4) * 1%;

					@include gridle_state(tablet) {
						width: (100 / 4) * 1%;
					}

					@include gridle_state(tablet-small) {
						width: (100 / 2) * 1%;
					}

					@include gridle_state(mobile-xs-small) {
						width: (100 / 1) * 1%;
					}
				}
			}
		}
	}
}
