.c-btn {
	&--trigger-nav-main {
		display: none;

		position: absolute;

		top: 50%;
		right: 20px;

		width: 40px;
		height: 40px;

		font-size: 0;
		text-indent: -9999px;

		border: 0;
		appearance: none;
		box-shadow: none;
		background: none;
		overflow: hidden;

		margin: 0;
		padding: 0;

		cursor: pointer;

		transform: translateY(-50%);

		@include transition(all, .3s);

		@include gridle_state(tablet) {
			display: block;
		}

		&:focus {
			outline: none;
		}

		&.is-active {

			span {
				background: none;

				&:before {
					top: 0;
					transform: rotate(45deg);
				}

				&:after {
					bottom: 0;
					transform: rotate(-45deg);
				}
			}
		}

		& > span {
			display: block;
			position: absolute;
			top: 18px;
			width: 40px;
			height: 4px;
			border-radius: 50px;
			background: $color_white;

			&:before, &:after {
				position: absolute;
				display: block;
				left: 0;
				width: 100%;
				height: 4px;
				border-radius: 40px;
				background: $color_white;
				content: "";
			}

			&:before {
				top: -10px;

				@include transition(all, .3s);
			}

			&:after {
				bottom: -10px;

				@include transition(all, .3s);
			}
		}
	}
}
