.b-box {
	&--articles {
		padding-top: 40px;

		@include gridle_state(laptop-middle) {
			padding-top: 20px;
		}

		@include gridle_state(tablet) {
			padding-top: 0;
		}

		&__btn {
			display: none;

			text-align: center;

			padding-top: 60px;

			@include gridle_state(laptop-middle) {
				padding-top: 40px;
			}

			&.is-visible {
				display: block;
			}
		}
	}
}