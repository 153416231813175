.b-box {
	&--default {
		padding-top: 40px;

		@include gridle_state(laptop-middle) {
			padding-top: 30px;
		}

		@include gridle_state(tablet) {
			padding-top: 20px;
		}

		&__content {
			padding-bottom: 40px;

			@include gridle_state(laptop-middle) {
				padding-bottom: 30px;
			}

			@include gridle_state(tablet) {
				padding-bottom: 20px;
			}
		}

		&__btn {
			display: flex;
			justify-content: center;

			padding-top: 30px;

			@include gridle_state(laptop-middle) {
				padding-top: 25px;
			}

			@include gridle_state(tablet) {
				padding-top: 15px;
			}
		}
	}
}