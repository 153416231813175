.c-image {
	&--wrap {
		position: relative;

		&--skew-right-around {

			&:before {
				position: absolute;

				top: 0;
				left: -32px;
				bottom: 0;

				width: 60px;

				background-color: $color_gray_4;

				transform: skew(-7deg);

				content: '';

				z-index: 2;
			}

			&:after {
				display: block;

				position: absolute;

				top: 0;
				right: -490px;

				height: 100%;
				width: 710px;

				background-color: $color_white;

				content: '';
			}
		}

		&__pic {
			position: relative;

			height: 382px;

			background-color: $color_white;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: left center;

			z-index: 1;

			@include gridle_state(laptop-middle) {
				background-size: cover;
			}

			@include gridle_state(tablet) {
				height: 430px;
			}

			@include gridle_state(tablet-small) {
				height: 300px;
			}
		}
	}
}