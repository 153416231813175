.b-box {
	&--contact-box {
		position: absolute;

		top: 65px;
		right: 0;

		width: 260px;
		height: 390px;

		background-color: $color_blue;

		transform: translateX(100%);

		@include transition(all, .3s);

		&.is-visible {
			transform: translateX(0);
		}

		&__btn {
			display: flex;
			align-items: center;
			justify-content: center;

			position: absolute;

			width: 50px;
			height: 50px;

			top: 0;
			left: -50px;

			border: 0;
			outline: none;
			background: $color_blue;

			cursor: pointer;
		}

		&__content {
			width: 100%;
			height: 100%;

			padding: 45px;
		}
	}
}