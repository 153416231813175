.c-list {
	&--breadcrumbs {
		display: flex;
		align-items: center;

		position: relative;

		height: 40px;

		list-style: none;

		padding: 0 50px 0 187px;
		margin: 0;

		@include gridle_state(laptop-middle) {
			padding-left: 217px;
		}

		@include gridle_state(laptop-small) {
			padding-left: 165px;
		}

		&__item {
			color: $color_white;

			&:after {
				display: inline-block;

				padding: 0 10px;

				content: '/';
			}

			&:last-child {
				&:after {
					display: none;
				}
			}

			.c-label {
				font-size: 1.4rem;

				color: $color_white;

				@include transition(opacity, .3s);

				&.is-active {
					font-weight: 700;
				}

				&:hover {
					opacity: 0.5;
				}
			}
		}
	}
}
