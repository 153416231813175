.b-box {
	&--popup {

		&__video {
			position: relative;

			height: 0;

			padding-bottom: 56.25%;
			padding-top: 25px;

			.mfp-iframe {
				position: absolute;

				top: 0;
				left: 0;

				width: 100%;
				height: 100%;

				padding: 0 20px;
			}

		}

		.mfp-close {

			@include gridle_state(laptop-middle) {
				right: 20px !important;
			}
		}
	}
}