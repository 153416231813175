.b-box {
	&--address {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		&--alt {
			padding: 90px 60px;

			@include gridle_state(laptop) {
				padding: 60px 30px;
			}
		}

		&__main-title {
			@extend %helper-clear-margins;

			font-family: $font_noticia;
			font-size: 2.6rem;
			font-weight: 400;
			font-style: italic;

			margin-bottom: 30px;

			@include gridle_state(laptop-middle) {
				font-size: 2.4rem;
			}

			@include gridle_state(mobile-middle) {
				font-size: 2.2rem;

				margin-bottom: 20px;
			}
		}

		&__marker {
			margin-bottom: 25px;

			@include gridle_state(mobile-middle) {
				margin-bottom: 15px;
			}
		}

		&__title {
			flex: 1 0 0;

			font-size: 2.4rem;
			font-weight: 300;
			color: $color_white;

			margin-top: 0;
			margin-bottom: 15px;

			@include gridle_state(laptop-middle) {
				font-size: 2.2rem;

				margin-bottom: 10px;
			}

			@include gridle_state(mobile-middle) {
				font-size: 2rem;
			}
		}

		&__content {
			margin-bottom: 30px;

			@include gridle_state(laptop-middle) {
				margin-bottom: 20px;
			}

			&__list {
				font-size: 1.6rem;
				font-weight: 300;

				list-style: none;

				padding: 0;

				@include gridle_state(mobile-middle) {
					font-size: 1.4rem;
				}

				li {
					padding: 2px 0;

					a {
						color: $color_white;

						@include transition(opacity, .3s);

						&:hover {
							opacity: 0.5;
						}
					}
				}
			}
		}

		&__btn {

		}
	}
}