.t-head {
	&--plain {
		@extend %helper-clear-margins;

		font-family: $font_noticia;
		font-size: 2.2rem;
		font-weight: 400;
		font-style: italic;

		margin-top: 0;
		margin-bottom: 20px;

		@include gridle_state(laptop-middle) {
			font-size: 2rem;
			margin-bottom: 10px !important;
		}

		@include gridle_state(tablet) {
			font-size: 1.8rem;

			margin-bottom: 5px;
		}
	}
}
