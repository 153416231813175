.c-carousel {
	&--default {
		height: 100%;

		@include gridle_state(tablet) {
			max-width: 800px;
		}

		@include gridle_state(tablet-small) {
			max-width: 600px;
		}

		@include gridle_state(mobile-middle) {
			max-width: 400px;
		}

		.c-carousel__items {
			display: flex;
			align-items: center;

			height: 100%;
		}

		.c-carousel__item {
			outline: none;
		}
	}
}