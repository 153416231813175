.o-ico {
	&--call {
		&,
		svg {
			display: block;

			width: 19px;
			height: 20px;

			fill: $color_white;
		}
	}
}