.b-box {
	&--lang {
		$this: &;

		position: relative;

		height: 100%;

		cursor: pointer;

		transition: all .3s;

		@include gridle_state(tablet) {
			position: absolute;

			height: 61px;
		}

		@include gridle_state(mobile-small) {
			position: absolute;

			height: 50px;
		}

		&__current {
			display: flex;
			justify-content: center;

			width: 50px;

			padding-top: 2px;

			@include gridle_state(laptop-small) {
				width: 47px;
			}

			@include gridle_state(tablet) {
				justify-content: flex-start;
			}

			span {
				position: absolute;

				top: 9px;
				right: 0;

				@include gridle_state(tablet) {
					right: 5px;
				}

				&:after {
					display: block;

					content: '';

					width: 9px;
					height: 4px;

					background: url("../img/icons/ico-arrow-1-white-down.png") no-repeat;
				}
			}
		}

		&__list {
			@extend %helper-reset-list;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;

			position: absolute;

			top: 45px;
			right: 0;

			height: 265px;
			width: 250px;

			z-index: 100;

			padding: 0 35px;

			background: $color_gray;

			opacity: 0;
			visibility: hidden;

			transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;

			@include gridle_state(tablet) {
				display: none;

				opacity: 1;
				visibility: visible;
				top: 61px;
				left: 0;
				right: auto;
			}

			@include gridle_state(mobile-small) {
				top: 50px;

				height: 210px;
				width: 180px;

				padding: 0 10px;
			}

			&__item {
				display: flex;
				justify-content: center;
				align-items: center;

				font-size: 1.6rem;

				padding: 8px 0;

				&.is-active {

					@include gridle_state(tablet) {

					}

					.c-label {

						&:hover {
							color: $color_white;
						}
					}
				}

				.c-image {
					margin-right: 10px;
				}

				.c-label {
					color: $color_black;
					flex-shrink: 0;

					&:hover {
						color: $color_white;
					}
				}
			}
		}

		&.is-active {
			border-bottom: 4px solid $color_red;

			#{$this} {

				&__list {
					display: flex;
				}
			}
		}

		&:hover {
			border-bottom: 4px solid $color_red;

			#{$this} {
				&__current {

					span:after {
						//transform: translateY(-50%) rotate(180deg);
					}
				}

				&__list {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
}