.b-box {
	&--news-teaser {
		display: block;

		color: $color_black;

		&:hover {
			.b-box--news-teaser__title {
				color: darken($color_red, 17%);
			}

			.b-box--news-teaser__desc {
				&:after {
					transform: translateX(5px);
				}
			}
		}

		&--alt {
			.b-box--news-teaser__body {
				padding: 15px 15px 15px 30px;

				@include gridle_state(tablet) {
					padding: 15px 20px;
				}

				@include gridle_state(mobile-middle) {
					padding: 15px;
				}

				@include gridle_state(mobile-xs-small) {
					padding: 5px;
				}
			}
		}

		&--video {
			&:after {
				display: block;
				position: absolute;

				top: calc(50% - 40px);
				left: 50%;

				content: url('../img/icons/ico-play.png');

				transform: translate(-50%, -50%);

				@include transition(all, .6s);
			}

			&:hover {

				&:after {
					top: 50%;
					//transform: translate(-50%, 250%);
				}
			}
		}

		&--large {
			position: relative;

			color: $color_white;

			width: 100%;
			min-height: 400px;

			overflow: hidden;

			@include gridle_state(laptop-middle) {
				min-height: 350px;
			}

			&:hover {

				.b-box--news-teaser__pic {
					transform: scale(1.05);
				}

				.b-box--news-teaser__body {
					bottom: -100%;
				}
			}

			.b-box--news-teaser__body {
				position: absolute;

				bottom: 0;
				left: 0;

				width: 100%;
				height: 120px;

				background: rgba(237, 28, 36, 0.7);

				padding: 15px 15px 15px 30px;

				@include transition(all, .6s);

				@include gridle_state(tablet) {
					padding: 15px 20px;
				}

				@include gridle_state(mobile-middle) {
					padding: 15px;
				}

				@include gridle_state(mobile-xs-small) {
					padding: 5px;
				}
			}

			.b-box--news-teaser__content {
				border-color: $color_white;
			}

			.b-box--news-teaser__title {
				color: $color_white;

				border-color: $color_white;
			}

			.b-box--news-teaser__desc {

				&:after {
					content: url('../img/icons/ico-arrow-3-white-right.png');
				}
			}
		}

		&__pic {
			width: 100%;
			height: 400px;

			background-size: cover;
			background-repeat: no-repeat;

			transform: scale(1);

			@include transition(all, .5s);

			@include gridle_state(laptop-middle) {
				height: 350px;
			}
		}

		&__body {
			display: flex;
			align-items: center;

			@include gridle_state(tablet) {
				padding: 0 20px;
			}

			@include gridle_state(mobile-middle) {
				padding: 0 15px;
			}

			@include gridle_state(mobile-xs-small) {
				padding: 0 5px;
			}
		}

		&__date {
			display: block;

			font-family: $font_noticia;
			font-size: 2rem;
			font-style: italic;

			width: 75px;

			padding-right: 30px;

			@include gridle_state(laptop-middle) {
				width: 65px;

				padding-right: 20px;
			}

			@include gridle_state(mobile-middle) {
				font-size: 1.6rem;

				width: 55px;
				padding-right: 10px;
			}

			span {
				display: block;

				line-height: 1.2;
			}
		}

		&__content {
			width: 100%;
			max-width: 460px;

			border-left: 1px solid $color_gray_5;

			padding-left: 20px;

			@include gridle_state(tablet) {
				max-width: 100%;
			}

			@include gridle_state(mobile-middle) {
				padding-left: 15px;
			}
		}

		&__title {
			display: block;

			font-family: $font_noticia;
			font-size: 2.2rem;
			font-style: italic;
			font-weight: 400;

			color: $color_red;

			border-bottom: 1px solid $color_gray_5;

			margin: 0;
			padding-bottom: 10px;

			transition: color 200ms ease-in-out;

			@include gridle_state(laptop-middle) {
				font-size: 2rem;
			}

			@include gridle_state(mobile-middle) {
				font-size: 1.8rem;
			}
		}

		&__desc {
			position: relative;

			margin: 0;
			padding-top: 10px;
			padding-right: 40px;

			@include gridle_state(laptop-small) {
				padding-right: 30px;
			}

			&:after {
				display: block;

				position: absolute;

				top: 10px;
				right: 20px;

				content: url('../img/icons/ico-arrow-3-red-right.png');

				@include transition(all, .5s);

				transform: translateX(0);

				@include gridle_state(laptop-small) {
					right: 10px;
				}
			}
		}
	}
}